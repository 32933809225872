import { SWRResponse } from 'swr';
import { UsersData, User, InvoiceDataInvAdmin, InvoiceDataSysAdmin } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useUser(accessToken: string | null): SWRResponse<User, BackendError> {
  const fullUrl = getFullUrl('/user');
  return getUseSwr<User>(fullUrl, accessToken);
}

export function useUsers(accessToken: string | null): SWRResponse<UsersData, BackendError> {
  const fullUrl = getFullUrl('/users');
  return getUseSwr<UsersData>(fullUrl, accessToken);
}

export function useInvoiceData(accessToken: string | null): SWRResponse<InvoiceDataInvAdmin | InvoiceDataSysAdmin, BackendError> {
  const fullUrl = getFullUrl('/subscription');
  return getUseSwr<InvoiceDataInvAdmin | InvoiceDataSysAdmin>(fullUrl, accessToken);
}
