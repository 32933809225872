import React from 'react';
import FleetDeviceSearch from './FleetDeviceSearch';
import TopCustomerTree from './TopCustomerTree';
import { TreeItemType } from '../../../model/frontendDataModels';

type SearchableTreeProps = {
  customerId: string;
  onlyCustomers: boolean;
  includeUnassigned: boolean;
  wideMode: boolean;
  canSelect: (treeItem: TreeItemType) => boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  expandedItems: string[];
  expandItem: (item: string) => void;
  expandItems: (itemIds: string[]) => void;
  collapseItem: (item: string) => void;
};

export default function SearchableTree(props: SearchableTreeProps): JSX.Element {
  return (
    <>
      <FleetDeviceSearch
        expandItems={props.expandItems}
        includeUnassigned={props.includeUnassigned}
        setItemSelection={props.setItemSelection}
      />
      <TopCustomerTree
        customerId={props.customerId}
        includeUnassigned={props.includeUnassigned}
        onlyCustomers={props.onlyCustomers}
        selectedTreeItems={props.selectedTreeItems}
        setItemSelection={props.setItemSelection}
        wideMode={props.wideMode}
        canSelect={props.canSelect}
        expandedItems={props.expandedItems}
        expandItem={props.expandItem}
        collapseItem={props.collapseItem}
      />
    </>
  );
}
