import React from 'react';
import Box from '@mui/material/Box';
import GatewayIdentifier from './GatewayIdentifier';
import GatewayType from './GatewayType';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';
import { BackendDevice } from '../../../../model/backendDataModels';

type Props = {
  selectedDevice: BackendDevice;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
};

export default function GatewayHeader({ selectedDevice, onFullscreenChanged, fullscreen }: Props): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '3px' }}>
        <GatewayType status={selectedDevice} />
        <GatewayIdentifier identifierType={'Device ID'} identifier={selectedDevice.deviceId} />
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '10px' }}>
          <ExpandViewSwitch wideMode={fullscreen} onChange={onFullscreenChanged} />
        </Box>
      </Box>
    </Box>
  );
}
