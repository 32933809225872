import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { User, UsersData } from '../../../model/backendDataModels';
import UserDetails from './UserDetails';
import { CustomerItemType, isCustomerItem, TreeItemType } from '../../../model/frontendDataModels';
import UserAccessSelector from './UserAccessSelector';
import { Box } from '@mui/material';

type Props = {
  user: User;
  selectedCustomer: CustomerItemType;
  usersData: UsersData;
  close: () => void;
  handleSave: (user: User) => Promise<void>;
  handleDeleteUser: (email: string) => Promise<boolean>;
};

export default function AddOrEditUser({ user, selectedCustomer, usersData, close, handleSave, handleDeleteUser }: Props): JSX.Element {
  const [selectedTreeCustomers, setSelectedTreeCustomers] = useState<TreeItemType[]>([]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          borderRight: '2px solid black',
          overflowX: 'auto',
          flexShrink: 0,
          padding: `${COMPONENT_PADDING}px`,
        }}
      >
        <UserDetails
          user={user}
          selectedCustomer={selectedCustomer}
          selectedTreeCustomers={selectedTreeCustomers}
          setSelectedTreeCustomers={setSelectedTreeCustomers}
          close={close}
          usersData={usersData}
          handleSave={handleSave}
          handleDeleteUser={handleDeleteUser}
        />
      </Paper>
      <UserAccessSelector
        customerId={selectedCustomer.customerId}
        canSelect={(treeItem: TreeItemType): boolean => {
          return isCustomerItem(treeItem);
        }}
        selectedTreeItems={selectedTreeCustomers}
        setItemSelection={(item, selected): void => {
          const newSelectedItems: CustomerItemType[] = [selectedCustomer];

          if (selected) {
            newSelectedItems.push(item as CustomerItemType);
          }

          setSelectedTreeCustomers(newSelectedItems);
        }}
      />
    </Box>
  );
}
