import { mutate, SWRResponse } from 'swr';
import { BackendCustomer } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useCustomers(parentId: string, accessToken: string | null): SWRResponse<BackendCustomer[], BackendError> {
  const fullUrl = getFullUrl(`/customer/${parentId}/customers`);

  return getUseSwr<BackendCustomer[]>(fullUrl, accessToken);
}

export function useCustomer(customerId: string | undefined, accessToken: string | null): SWRResponse<BackendCustomer, BackendError> {
  const fullUrl = customerId ? getFullUrl(`/customer/${customerId}`) : null;

  return getUseSwr<BackendCustomer>(fullUrl, accessToken);
}

export function mutateCustomers(parentId: string): Promise<void> {
  const fullUrl = getFullUrl(`/customer/${parentId}/customers`);

  return mutate<void>(fullUrl);
}

export function mutateCustomer(customerId: string): Promise<void> {
  const fullUrl = getFullUrl(`/customer/${customerId}`);

  return mutate<void>(fullUrl);
}
