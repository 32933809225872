import React, { useState, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import {
  TreeItemType,
  isUnassignedFleetItem,
  isDeviceItem,
  isCustomerItem,
  isFleetItem,
  isFleetgroupItem,
} from '../../model/frontendDataModels';
import AddEditDeleteFooter from './addEditDeleteFooter/AddEditDeleteFooter';
import TransferButton from './TransferButton';
import HelpBox from '../common/HelpBox';
import HelpBoxArrange from './HelpBoxArrange';
import FleetBrowser from '../common/FleetBrowser';
import { dummyCustomerItem } from '../../model/convertModels';

function isSameItem(item: TreeItemType, other: TreeItemType): boolean {
  if (isCustomerItem(item) && isCustomerItem(other)) {
    return item.customerId === other.customerId;
  } else if (isDeviceItem(item) && isDeviceItem(other)) {
    return item.mui === other.mui;
  } else if (isUnassignedFleetItem(item) && isUnassignedFleetItem(other)) {
    return item.fleetId === other.fleetId;
  } else if (isFleetItem(item) && isFleetItem(other)) {
    return item.fleetId === other.fleetId;
  } else if (isFleetgroupItem(item) && isFleetgroupItem(other)) {
    return item.fleetgroupId === other.fleetgroupId;
  }

  return false;
}

function isSameType(item: TreeItemType, other: TreeItemType): boolean {
  if (isCustomerItem(item) && isCustomerItem(other)) {
    return true;
  } else if (isDeviceItem(item) && isDeviceItem(other)) {
    return true;
  } else if (isUnassignedFleetItem(item) && isUnassignedFleetItem(other)) {
    return true;
  } else if (isFleetItem(item) && isFleetItem(other)) {
    return true;
  } else if (isFleetgroupItem(item) && isFleetgroupItem(other)) {
    return true;
  }

  return false;
}

type ItemAction = {
  item: TreeItemType;
  selected: boolean;
  clear: boolean;
};

const leftSelectedItemsReducer = (state: TreeItemType[], action: ItemAction): TreeItemType[] => {
  if (action.clear) {
    return [];
  }

  // Only select one type at a time
  const differentTypes = state.filter(item => !isSameType(action.item, item)).length > 0;
  if (differentTypes) {
    return [action.item];
  }

  const filteredItems = state.filter(item => !isSameItem(action.item, item));

  if (action.selected) {
    if (isDeviceItem(action.item)) {
      return [...filteredItems, action.item];
    } else {
      return [action.item];
    }
  } else {
    return filteredItems;
  }
};

type ArrangeProps = {
  customerId: string;
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function Arrange(props: ArrangeProps): JSX.Element {
  const [leftSelectedTreeItems, leftSelectedItemsDispatch] = useReducer(leftSelectedItemsReducer, []);

  const [leftExpandedItems, setLeftExpandedItems] = useState<string[]>([]);
  const [rightSelectedTreeItem, setRightSelectedTreeItem] = useState<TreeItemType | undefined>(undefined);
  const [rightExpandedItems, setRightExpandedItems] = useState<string[]>([]);

  const dummyItem = dummyCustomerItem();

  useEffect(() => {
    props.changeHelpBox(<HelpBox hide={props.hideHelpBox} title='Arrange' info={<HelpBoxArrange />} />);
  }, []);

  function expandLeftItem(itemId: string): void {
    setLeftExpandedItems([...leftExpandedItems, itemId]);
  }

  function expandLeftItems(items: string[]): void {
    setLeftExpandedItems([...leftExpandedItems, ...items]);
  }

  function collapseLeftItem(itemId: string): void {
    setLeftExpandedItems(leftExpandedItems.filter(id => id !== itemId));
  }

  function expandRightItem(itemId: string): void {
    setRightExpandedItems([...rightExpandedItems, itemId]);
  }

  function expandRightItems(items: string[]): void {
    setRightExpandedItems([...rightExpandedItems, ...items]);
  }

  function collapseRightItem(itemId: string): void {
    setRightExpandedItems(rightExpandedItems.filter(id => id !== itemId));
  }

  return (
    <Box component='div' sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <FleetBrowser
        customerId={props.customerId}
        canSelect={(treeItem: TreeItemType): boolean => {
          if (isUnassignedFleetItem(treeItem)) {
            return false;
          }
          if (isUnassignedFleetItem(rightSelectedTreeItem)) {
            return false;
          }
          return true;
        }}
        selectedTreeItems={leftSelectedTreeItems}
        setItemSelection={(item, selected): void => {
          leftSelectedItemsDispatch({ item, selected, clear: false });
        }}
        footerComponent={
          <AddEditDeleteFooter
            selectedTreeItems={leftSelectedTreeItems}
            clearSelection={(): void => {
              leftSelectedItemsDispatch({ item: dummyItem, selected: false, clear: true });
            }}
          />
        }
        footerHeight={150}
        includeUnassigned={true}
        expandedItems={leftExpandedItems}
        expandItem={expandLeftItem}
        expandItems={expandLeftItems}
        collapseItem={collapseLeftItem}
      />
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: 200,
          borderRight: '2px solid black',
          overflow: 'hidden',
          flexShrink: 0,
          alignItems: 'center',
        }}
      >
        <TransferButton
          leftSelectedTreeItems={leftSelectedTreeItems}
          rightSelectedTreeItem={rightSelectedTreeItem}
          clearLeftSelectedTreeItems={(): void => {
            leftSelectedItemsDispatch({ item: dummyItem, selected: false, clear: true });
          }}
        />
      </Box>
      <FleetBrowser
        customerId={props.customerId}
        canSelect={(treeItem: TreeItemType): boolean => {
          if (isDeviceItem(treeItem)) {
            return false;
          }

          return true;
        }}
        selectedTreeItems={rightSelectedTreeItem ? [rightSelectedTreeItem] : []}
        setItemSelection={(item, selected): void => {
          setRightSelectedTreeItem(selected ? item : undefined);
        }}
        includeUnassigned={true}
        expandedItems={rightExpandedItems}
        expandItem={expandRightItem}
        expandItems={expandRightItems}
        collapseItem={collapseRightItem}
      />
    </Box>
  );
}
