import React, { useContext, useState } from 'react';
import { WIDTH_OF_INFO_COMPONENT, COMPONENT_PADDING } from '../../../themes/theme';
import SelectedFleetOrGroupHeader from '../common/selectedFleetOrGroupHeader/SelectedFleetOrGroupHeader';
import FleetOrGroupMonitoring from '../common/fleetOrGroupMonitoring/FleetOrGroupMonitoring';
import { FleetgroupItemType } from '../../../model/frontendDataModels';
import { Box, useTheme } from '@mui/material';
import { AppContext } from '../../../App';
import { useFleetgroup } from '../../../dataHooks/fleetAndFleetgroupHooks';
import LoadingIndicator from '../../common/LoadingIndicator';
import { LoginContext } from '../../../Login';

type SelectedFleetgroupViewProps = {
  fleetgroup: FleetgroupItemType;
};

export default function SelectedFleetgroupView(props: SelectedFleetgroupViewProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();

  const { data: fleetgroup, error } = useFleetgroup(props.fleetgroup.fleetgroupId, loginContext.accessToken);

  const width = expanded ? 2 * WIDTH_OF_INFO_COMPONENT + 2 * COMPONENT_PADDING : WIDTH_OF_INFO_COMPONENT + COMPONENT_PADDING;

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '2px solid black',
        overflowX: 'auto',
        width,
        maxWidth: '100%',
      }}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      {fleetgroup ? (
        <>
          <SelectedFleetOrGroupHeader fleetOrFleetgroup={fleetgroup} expanded={expanded} changeExpanded={setExpanded} />
          <FleetOrGroupMonitoring selectedFleetOrFleetgroup={fleetgroup} expanded={expanded} />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingIndicator />
        </Box>
      )}
    </Box>
  );
}
