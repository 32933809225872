import { SWRResponse } from 'swr';
import { BmsBasicLogInfo, BmsHistograms, BmsOverviewStatus, FieldsAndValues } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useBmsBasicLogInfo(mui: string, accessToken: string | null): SWRResponse<BmsBasicLogInfo, BackendError> {
  const fullUrl = getFullUrl(`/device/${mui}/logs/basicinfo`);
  return getUseSwr<BmsBasicLogInfo>(fullUrl, accessToken);
}

export function useBmsHistogram(mui: string, accessToken: string | null): SWRResponse<BmsHistograms, BackendError> {
  const fullUrl = getFullUrl(`/bms/${mui}/logs/histogram`);
  return getUseSwr<BmsHistograms>(fullUrl, accessToken);
}

export function useProtectiveLogs(
  mui: string,
  from: Date,
  to: Date,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/bms/${mui}/logs/protective`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });
  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useBmsOverviewStatus(mui: string, accessToken: string | null): SWRResponse<BmsOverviewStatus, BackendError> {
  const fullUrl = getFullUrl(`/device/${mui}/overview/status`);

  return getUseSwr<BmsOverviewStatus>(fullUrl, accessToken);
}
