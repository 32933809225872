import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoDialog from '../common/InfoDialog';
import LoadingIndicator from '../common/LoadingIndicator';
import {
  CustomerItemType,
  DeviceItemType,
  FleetgroupItemType,
  FleetItemType,
  isCustomerItem,
  isDeviceItem,
  isFleetgroupItem,
  isFleetItem,
  isUnassignedFleetItem,
  TreeItemType,
} from '../../model/frontendDataModels';
import { moveDevices } from '../../services/deviceManipulation';
import { LoginContext } from '../../Login';
import { AppContext } from '../../App';
import { BackendError } from '../../utils/BackendError';
import ArrangeButton from './addEditDeleteFooter/common/ArrangeButton';
import { mutateDevices } from '../../dataHooks/deviceHooks';
import { fleetChangeParent } from '../../services/fleetManipulation';
import { mutateFleetgroups, mutateFleets } from '../../dataHooks/fleetAndFleetgroupHooks';
import { fleetgroupChangeParent } from '../../services/fleetgroupManipulation';
import { customerChangeParent } from '../../services/customerManipulation';
import { mutateCustomers } from '../../dataHooks/customerHooks';

type UserInfoData = {
  title: string;
  body: string;
};

type TransferButtonProps = {
  leftSelectedTreeItems: TreeItemType[];
  rightSelectedTreeItem?: TreeItemType;
  clearLeftSelectedTreeItems: () => void;
};

export default function TransferButton({
  leftSelectedTreeItems,
  rightSelectedTreeItem,
  clearLeftSelectedTreeItems,
}: TransferButtonProps): JSX.Element {
  const [userInfo, setUserInfo] = useState<UserInfoData | undefined>(undefined);
  const [awaitingResult, setAwaitingResult] = useState<boolean>(false);

  const leftOneItemSelected = leftSelectedTreeItems.length === 1;
  const leftOnlyDevices = leftSelectedTreeItems.findIndex(d => !isDeviceItem(d)) === -1;
  const isRightFleetItem = isFleetItem(rightSelectedTreeItem) || isUnassignedFleetItem(rightSelectedTreeItem);
  const isRightGroupOrCustomer = isFleetgroupItem(rightSelectedTreeItem) || isCustomerItem(rightSelectedTreeItem);
  const isLeftFleetItem = leftOneItemSelected && isFleetItem(leftSelectedTreeItems[0]);
  const isLeftFleetgroupItem = leftOneItemSelected && isFleetgroupItem(leftSelectedTreeItems[0]);
  const isLeftCustomerItem = leftOneItemSelected && isCustomerItem(leftSelectedTreeItems[0]);
  const isRightCustomerItem = isCustomerItem(rightSelectedTreeItem);
  const moveDevicesPossible = isRightFleetItem && leftSelectedTreeItems.length > 0 && leftOnlyDevices;
  const moveFleetPossible =
    isLeftFleetItem &&
    isRightGroupOrCustomer &&
    isFleetItem(leftSelectedTreeItems[0]) &&
    leftSelectedTreeItems[0].parentId !==
      (isFleetgroupItem(rightSelectedTreeItem) ? rightSelectedTreeItem.fleetgroupId : rightSelectedTreeItem.customerId);
  const moveFleetgroupPossible =
    isLeftFleetgroupItem &&
    isRightGroupOrCustomer &&
    isFleetgroupItem(leftSelectedTreeItems[0]) &&
    leftSelectedTreeItems[0].parentId !==
      (isFleetgroupItem(rightSelectedTreeItem) ? rightSelectedTreeItem.fleetgroupId : rightSelectedTreeItem.customerId) &&
    leftSelectedTreeItems[0].fleetgroupId !==
      (isFleetgroupItem(rightSelectedTreeItem) ? rightSelectedTreeItem.fleetgroupId : rightSelectedTreeItem.customerId);
  const moveCustomerPossible =
    isLeftCustomerItem &&
    isRightCustomerItem &&
    isCustomerItem(leftSelectedTreeItems[0]) &&
    leftSelectedTreeItems[0].parentId !== rightSelectedTreeItem.customerId &&
    leftSelectedTreeItems[0].customerId !== rightSelectedTreeItem.customerId;

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  async function transferDevices(devices: DeviceItemType[], newParent: TreeItemType): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: 'Devices was not moved',
      };
    }

    const fleetId = isFleetItem(newParent) ? newParent.fleetId : '';
    const customerId = isUnassignedFleetItem(newParent) ? newParent.customerId : isFleetItem(newParent) ? newParent.customerId : '';
    const result = await moveDevices(
      {
        fleetId: fleetId,
        customerId: customerId,
        muis: devices.map(device => device.mui),
      },
      loginContext.accessToken,
      appContext.addBackendError
    );

    if (result && devices.length > 0) {
      clearLeftSelectedTreeItems();
      await Promise.all([mutateDevices(devices[0].customerId, devices[0].fleetId), mutateDevices(customerId, fleetId)]);
    }

    return {
      title: result ? 'Success' : 'Failure',
      body: result
        ? `${devices.length} devices has been moved to ${
            isUnassignedFleetItem(newParent) ? newParent.parentName : isFleetItem(newParent) ? newParent.name : ''
          }`
        : `Failed to move ${devices.length} of ${devices.length} devices`,
    };
  }

  async function transferFleet(fleet: FleetItemType, newParent: CustomerItemType | FleetgroupItemType): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: 'Devices was not moved',
      };
    }

    const parentId = isCustomerItem(newParent) ? newParent.customerId : newParent.fleetgroupId;
    const result = await fleetChangeParent(
      fleet.fleetId,
      {
        parentId,
        isParentFleetgroup: isFleetgroupItem(newParent),
      },
      loginContext.accessToken,
      appContext.addBackendError
    );

    if (result) {
      clearLeftSelectedTreeItems();
      await Promise.all([
        mutateFleets(fleet.parentType, fleet.parentId),
        mutateFleets(isFleetgroupItem(newParent) ? 'fleetgroup' : 'customer', parentId),
      ]);
    }

    return {
      title: result ? 'Success' : 'Failure',
      body: result ? `${fleet.name} has been moved to ${newParent.name}` : `${fleet.name} has NOT been moved`,
    };
  }

  async function transferFleetgroup(
    fleetgroup: FleetgroupItemType,
    newParent: CustomerItemType | FleetgroupItemType
  ): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: 'Devices was not moved',
      };
    }

    const parentId = isCustomerItem(newParent) ? newParent.customerId : newParent.fleetgroupId;
    const result = await fleetgroupChangeParent(
      fleetgroup.fleetgroupId,
      {
        parentId,
        isParentFleetgroup: isFleetgroupItem(newParent),
      },
      loginContext.accessToken,
      appContext.addBackendError
    );

    if (result) {
      clearLeftSelectedTreeItems();
      await Promise.all([
        mutateFleetgroups(fleetgroup.parentType, fleetgroup.parentId),
        mutateFleetgroups(isFleetgroupItem(newParent) ? 'fleetgroup' : 'customer', parentId),
      ]);
    }

    return {
      title: result ? 'Success' : 'Failure',
      body: result ? `${fleetgroup.name} has been moved to ${newParent.name}` : `${fleetgroup.name} has NOT been moved`,
    };
  }

  async function transferCustomer(customer: CustomerItemType, newParent: CustomerItemType): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: 'Devices was not moved',
      };
    }

    const parentId = newParent.customerId;
    const result = await customerChangeParent(
      customer.customerId,
      {
        parentId,
      },
      loginContext.accessToken,
      appContext.addBackendError
    );

    if (result) {
      clearLeftSelectedTreeItems();
      await Promise.all([mutateCustomers(customer.parentId), mutateCustomers(parentId)]);
    }

    return {
      title: result ? 'Success' : 'Failure',
      body: result ? `${customer.name} has been moved to ${newParent.name}` : `${customer.name} has NOT been moved`,
    };
  }

  async function transfer(): Promise<UserInfoData> {
    if (moveDevicesPossible && rightSelectedTreeItem) {
      return transferDevices(leftSelectedTreeItems as DeviceItemType[], rightSelectedTreeItem);
    } else if (moveFleetPossible) {
      return transferFleet(leftSelectedTreeItems[0] as FleetItemType, rightSelectedTreeItem);
    } else if (moveFleetgroupPossible) {
      return transferFleetgroup(leftSelectedTreeItems[0] as FleetgroupItemType, rightSelectedTreeItem);
    } else if (moveCustomerPossible) {
      return transferCustomer(leftSelectedTreeItems[0] as CustomerItemType, rightSelectedTreeItem);
    } else {
      return {
        title: 'Failure',
        body: 'Unknown error!',
      };
    }
  }

  return (
    <Paper sx={{ padding: '20px', width: '100%', borderTop: '2px solid black', borderBottom: '2px solid black' }}>
      {!awaitingResult && (
        <ArrangeButton
          title='→ Transfer →'
          onClick={async (): Promise<void> => {
            setAwaitingResult(true);
            setUserInfo(await transfer());
            setAwaitingResult(false);
          }}
          disabled={!(moveDevicesPossible || moveFleetPossible || moveFleetgroupPossible || moveCustomerPossible)}
        />
      )}
      {awaitingResult && <LoadingIndicator />}

      {userInfo && (
        <InfoDialog open={!!userInfo} close={(): void => setUserInfo(undefined)} title={userInfo.title}>
          <Typography variant='tableText'>{userInfo.body}</Typography>
        </InfoDialog>
      )}
    </Paper>
  );
}
