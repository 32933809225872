import React, { useContext } from 'react';
import FleetOrGroupOverviewInfo from '../FleetOrGroupOverviewInfo';
import { BackendFleet, BackendFleetgroup, isBackendFleet } from '../../../../model/backendDataModels';
import { AppContext } from '../../../../App';
import { useFleetgroupInfo, useFleetInfo } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { LoginContext } from '../../../../Login';

type OverviewTabProps = {
  fleetOrFleetgroup: BackendFleet | BackendFleetgroup;
};

export default function OverviewTab(props: OverviewTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const {
    data: info,
    error: infoError,
    isLoading,
  } = isBackendFleet(props.fleetOrFleetgroup)
    ? useFleetInfo(props.fleetOrFleetgroup.fleetId, loginContext.accessToken)
    : useFleetgroupInfo(props.fleetOrFleetgroup.fleetgroupId, loginContext.accessToken);

  const chargerCount = info?.charger ?? 0;
  const batteryCount = info?.bmu ?? 0;
  const gatewayCount = info?.gateway ?? 0;

  if (infoError) {
    appContext.addBackendError(infoError);
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <FleetOrGroupOverviewInfo
      chargerCount={chargerCount}
      batteryCount={batteryCount}
      gatewayCount={gatewayCount}
      selectedFleetOrFleetgroup={props.fleetOrFleetgroup}
    />
  );
}
