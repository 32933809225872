import React, { useEffect } from 'react';
import { TreeItemType, isFleetgroupItem, isFleetItem, isDeviceItem } from '../../model/frontendDataModels';
import SelectedFleetView from './selectedFleetView/SelectedFleetView';
import SelectedFleetgroupView from './selectedFleetgroupView/SelectedFleetgroupView';
import SelectedDeviceView from './selectedDeviceView/SelectedDeviceView';
import FleetBrowser from '../common/FleetBrowser';

type Props = {
  customerId: string;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
  expandedItems: string[];
  expandItem: (item: string) => void;
  expandItems: (itemIds: string[]) => void;
  collapseItem: (item: string) => void;
};

export default function FleetView(props: Props): JSX.Element {
  const selectedTreeItem = props.selectedTreeItems.length > 0 ? props.selectedTreeItems[0] : undefined;

  useEffect(() => {
    props.changeHelpBox(undefined);
  }, []);

  return (
    <>
      <FleetBrowser
        customerId={props.customerId}
        canSelect={(): boolean => true}
        includeUnassigned={false}
        selectedTreeItems={props.selectedTreeItems}
        setItemSelection={props.setItemSelection}
        expandedItems={props.expandedItems}
        expandItem={props.expandItem}
        expandItems={props.expandItems}
        collapseItem={props.collapseItem}
      />
      {isDeviceItem(selectedTreeItem) && <SelectedDeviceView device={selectedTreeItem} />}
      {isFleetgroupItem(selectedTreeItem) && <SelectedFleetgroupView fleetgroup={selectedTreeItem} />}
      {isFleetItem(selectedTreeItem) && <SelectedFleetView fleet={selectedTreeItem} />}
    </>
  );
}
