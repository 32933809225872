import { SWRResponse } from 'swr';
import { CalendarStateData, ChargerOverviewStatus, LineChartData } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useChargerDailyConnects(
  mui: string,
  year: number,
  month: number,
  accessToken: string | null
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/dailyconnect`, {
    year,
    month,
  });

  return getUseSwr<CalendarStateData>(fullUrl, accessToken);
}

export function useChargerUsedMains(
  mui: string,
  year: number,
  month: number,
  accessToken: string | null
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/mainsenergy`, { year, month });

  return getUseSwr<LineChartData>(fullUrl, accessToken);
}

export function useChargerOverviewStatus(mui: string, accessToken: string | null): SWRResponse<ChargerOverviewStatus, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/status`);

  return getUseSwr<ChargerOverviewStatus>(fullUrl, accessToken);
}
