import React, { useState, useContext } from 'react';
import EditFleetgroup from './EditFleetgroup';
import EditCustomer from './EditCustomer';
import EditFleet from './EditFleet';
import {
  CustomerItemType,
  FleetgroupItemType,
  isCustomerItem,
  isFleetgroupItem,
  isFleetItem,
  TreeItemType,
} from '../../../../model/frontendDataModels';
import { editCustomer, EditCustomerData } from '../../../../services/customerManipulation';
import { editFleetgroup, EditFleetgroupData } from '../../../../services/fleetgroupManipulation';
import { editFleet, EditFleetData } from '../../../../services/fleetManipulation';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import ArrangeButton from '../common/ArrangeButton';
import { BackendCustomer, BackendFleet, BackendFleetgroup } from '../../../../model/backendDataModels';
import { mutateFleet, mutateFleetgroup, mutateFleetgroups, mutateFleets } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { mutateCustomer, mutateCustomers } from '../../../../dataHooks/customerHooks';

type EditButtonProps = {
  selectedItem?: TreeItemType;
};

export default function EditButton(props: EditButtonProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const [editCustomerPopupOpen, setEditCustomerPopupOpen] = useState(false);
  const [editFleetgroupPopupOpen, setEditFleetgroupPopupOpen] = useState(false);
  const [editFleetPopupOpen, setEditFleetPopupOpen] = useState(false);
  const appContext = useContext(AppContext);

  async function updateCustomer(treeCustomer: BackendCustomer, newData: EditCustomerData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }

    const result = await editCustomer(treeCustomer.customerId, newData, loginContext.accessToken, appContext.addBackendError);

    if (result) {
      await mutateCustomers(treeCustomer.parentId);
      await mutateCustomer(treeCustomer.customerId);
    }

    return result;
  }

  async function updateFleetgroup(treeFleetgroup: BackendFleetgroup, newData: EditFleetgroupData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }

    const result = await editFleetgroup(treeFleetgroup.fleetgroupId, newData, loginContext.accessToken, appContext.addBackendError);

    if (result) {
      await mutateFleetgroups(treeFleetgroup.fleetGroupChildren ? 'fleetgroup' : 'customer', treeFleetgroup.parentId);
      await mutateFleetgroup(treeFleetgroup.fleetgroupId);
    }

    return result;
  }

  async function updateFleet(treeFleet: BackendFleet, newData: EditFleetData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }

    const result = await editFleet(treeFleet.fleetId, newData, loginContext.accessToken, appContext.addBackendError);

    if (result) {
      await mutateFleets(treeFleet.fleetGroupChildren ? 'fleetgroup' : 'customer', treeFleet.parentId);
      await mutateFleet(treeFleet.fleetId);
    }

    return result;
  }

  return (
    <>
      <ArrangeButton
        title='Edit'
        onClick={(): void => {
          if (isCustomerItem(props.selectedItem)) {
            setEditCustomerPopupOpen(true);
          } else if (isFleetgroupItem(props.selectedItem)) {
            setEditFleetgroupPopupOpen(true);
          } else if (isFleetItem(props.selectedItem)) {
            setEditFleetPopupOpen(true);
          }
        }}
        disabled={!(isCustomerItem(props.selectedItem) || isFleetgroupItem(props.selectedItem) || isFleetItem(props.selectedItem))}
      />
      {isCustomerItem(props.selectedItem) && (
        <EditCustomer
          customer={props.selectedItem as CustomerItemType}
          open={editCustomerPopupOpen}
          close={(): void => setEditCustomerPopupOpen(false)}
          updateCustomer={updateCustomer}
        />
      )}
      {parent && isFleetgroupItem(props.selectedItem) && (
        <EditFleetgroup
          fleetgroup={props.selectedItem as FleetgroupItemType}
          open={editFleetgroupPopupOpen}
          close={(): void => setEditFleetgroupPopupOpen(false)}
          updateFleetgroup={updateFleetgroup}
        />
      )}
      {parent && isFleetItem(props.selectedItem) && (
        <EditFleet
          treeFleet={props.selectedItem}
          open={editFleetPopupOpen}
          close={(): void => setEditFleetPopupOpen(false)}
          editFleet={updateFleet}
        />
      )}
    </>
  );
}
