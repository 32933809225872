import React, { useState, useContext } from 'react';
import AddCustomer from './AddCustomer';
import AddFleetgroup from './AddFleetgroup';
import AddFleet from './AddFleet';
import { isCustomerItem, isFleetgroupItem, TreeItemType } from '../../../../model/frontendDataModels';
import { addCustomer, AddCustomerData } from '../../../../services/customerManipulation';
import { addFleetgroup, AddFleetgroupData } from '../../../../services/fleetgroupManipulation';
import { addFleet, AddFleetData } from '../../../../services/fleetManipulation';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import ArrangeButton from '../common/ArrangeButton';
import { Box } from '@mui/material';
import { mutateCustomers } from '../../../../dataHooks/customerHooks';
import { mutateFleetgroups, mutateFleets } from '../../../../dataHooks/fleetAndFleetgroupHooks';

type AddButtonsProps = {
  selectedItem?: TreeItemType;
};

export default function AddButtons(props: AddButtonsProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [addCustomerPopupOpen, setAddCustomerPopupOpen] = useState(false);
  const [addFleetgroupPopupOpen, setAddFleetgroupPopupOpen] = useState(false);
  const [addFleetPopupOpen, setAddFleetPopupOpen] = useState(false);

  async function addNewCustomer(newCustomer: AddCustomerData): Promise<void> {
    if (loginContext.accessToken) {
      await addCustomer(newCustomer, loginContext.accessToken, appContext.addBackendError);
      await mutateCustomers(newCustomer.parentId);
    } else {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
    }
  }

  async function addNewFleetgroup(newFleetgroup: AddFleetgroupData): Promise<void> {
    if (loginContext.accessToken) {
      await addFleetgroup(newFleetgroup, loginContext.accessToken, appContext.addBackendError);
      await mutateFleetgroups(newFleetgroup.isParentFleetgroup ? 'fleetgroup' : 'customer', newFleetgroup.parentId);
    } else {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
    }
  }

  async function addNewFleet(newFleet: AddFleetData): Promise<boolean> {
    if (loginContext.accessToken) {
      const result = await addFleet(newFleet, loginContext.accessToken, appContext.addBackendError);
      await mutateFleets(newFleet.isParentFleetgroup ? 'fleetgroup' : 'customer', newFleet.parentId);

      if (result) {
        return true;
      }

      return false;
    } else {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
      <ArrangeButton
        title='Add Customer'
        onClick={(): void => {
          setAddCustomerPopupOpen(true);
        }}
        disabled={!isCustomerItem(props.selectedItem)}
      />
      <ArrangeButton
        title='Add Fleet Group'
        onClick={(): void => {
          setAddFleetgroupPopupOpen(true);
        }}
        disabled={!(isCustomerItem(props.selectedItem) || isFleetgroupItem(props.selectedItem))}
      />
      <ArrangeButton
        title='Add Fleet'
        onClick={(): void => {
          setAddFleetPopupOpen(true);
        }}
        disabled={!(isCustomerItem(props.selectedItem) || isFleetgroupItem(props.selectedItem))}
      />
      <>
        {isCustomerItem(props.selectedItem) ? (
          <AddCustomer
            parent={props.selectedItem}
            open={addCustomerPopupOpen}
            close={(): void => setAddCustomerPopupOpen(false)}
            addNewCustomer={addNewCustomer}
          />
        ) : null}
        {isCustomerItem(props.selectedItem) || isFleetgroupItem(props.selectedItem) ? (
          <>
            <AddFleetgroup
              parent={props.selectedItem}
              open={addFleetgroupPopupOpen}
              close={(): void => setAddFleetgroupPopupOpen(false)}
              addNewFleetgroup={addNewFleetgroup}
            />
            <AddFleet
              parent={props.selectedItem}
              open={addFleetPopupOpen}
              close={(): void => setAddFleetPopupOpen(false)}
              addFleet={addNewFleet}
            />
          </>
        ) : null}
      </>
    </Box>
  );
}
