import * as React from 'react';
import { useContext, useState } from 'react';
import ComponentBase from '../../common/componentBase/ComponentBase';
import MonthAndYearPicker from '../../../common/monthAndYearPicker/MonthAndYearPicker';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { utcToZonedTime } from 'date-fns-tz';
import Store from '../../../../store/Store';
import QuantityCalendar from './QuantityCalendar';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import { CalendarStateData } from '../../../../model/backendDataModels';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';

type DailyQuantityCalendarProps = {
  mui: string;
  dataHook: (mui: string, year: number, month: number, accessToken: string | null) => SWRResponse<CalendarStateData, BackendError>;
  title: string;
  help?: React.ReactNode;
};

export default function DailyQuantityCalendar({ mui, dataHook, title, help }: DailyQuantityCalendarProps): JSX.Element {
  const appContext = useContext(AppContext);
  const loginContext = useContext(LoginContext);
  const { state } = useContext(Store);

  const date = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  const { data: dailyConnects, error } = dataHook(mui, year, month + 1, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <ComponentBase title={title} help={help}>
      <MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />
      {dailyConnects && !error && <QuantityCalendar month={month} year={year} dailyConnects={dailyConnects} />}
      {!dailyConnects && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
