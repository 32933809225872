import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import InputItem from '../../../common/InputItem';
import { FleetItemType } from '../../../../model/frontendDataModels';
import InputLongText from '../../../common/InputLongText';
import getTimeZone from '../../../../services/getTimeZone';
import { EditFleetData } from '../../../../services/fleetManipulation';
import reverseGeocoding from '../../../../services/reverseGeocoding';
import { AppContext } from '../../../../App';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { useFleet, useFleetgroup } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { BackendFleet } from '../../../../model/backendDataModels';
import useSWR from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import AddEditFleetMap, { LngLatType } from '../common/addEditFleet/AddEditFleetMap';
import AddEditDialog from '../common/AddEditDialog';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { LoginContext } from '../../../../Login';
import { getSwrRevalidationOptions } from '../../../../utils/getSwrRevalidationOptions';

function prettifyLocation(location: LngLatType): string {
  const obj: { lat: number; lng: number } = JSON.parse(JSON.stringify(location));
  const value = `Lat: ${obj.lat.toFixed(6)}, Lng: ${obj.lng.toFixed(6)}`;
  return value;
}

type EditFleetProps = {
  treeFleet: FleetItemType;
  open: boolean;
  close: () => void;
  editFleet: (treeFleet: BackendFleet, fleetData: EditFleetData) => Promise<boolean>;
};

export default function EditFleet(props: EditFleetProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [location, setLocation] = useState<LngLatType | undefined>(undefined);
  const [address, setAddress] = useState<string | null>(null);
  const [timeZoneFromLocation, setTimeZoneFromLocation] = useState<string | undefined>(undefined);
  const [timeZoneFromList, setTimeZoneFromList] = useState<string | null>(null);

  const { data: treeFleet, error: treeFleetError } = useFleet(props.treeFleet?.fleetId, loginContext.accessToken);
  const { data: parent, error: parentError } = treeFleet
    ? treeFleet.fleetGroupChildren
      ? useFleetgroup(treeFleet.parentId, loginContext.accessToken)
      : useCustomer(treeFleet.parentId, loginContext.accessToken)
    : useSWR<undefined, BackendError>(null, null, getSwrRevalidationOptions(''));

  if (treeFleetError) {
    appContext.addBackendError(treeFleetError);
  }

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  useEffect(() => {
    if (treeFleet) {
      setName(treeFleet.name);
      setDescription(treeFleet.description);
      setLocation(treeFleet.lat && treeFleet.lng ? { lat: treeFleet.lat, lng: treeFleet.lng } : undefined);
      setTimeZoneFromList(treeFleet.timezone);
    }
  }, [treeFleet]);

  useEffect(() => {
    resetValues();
  }, [props.treeFleet]);

  useEffect(() => {
    if (location) {
      updateTimeZone(location);
      setLat(location.lat);
      setLng(location.lng);
      updateAddress(location);
    } else {
      setTimeZoneFromLocation(undefined);
      setLat(null);
      setLng(null);
      setAddress(null);
    }
  }, [location]);

  async function updateTimeZone(location: LngLatType): Promise<void> {
    const timezone = await getTimeZone(location);
    if (timezone) {
      setTimeZoneFromLocation(timezone);
      setTimeZoneFromList(timezone);
    }
  }

  function resetValues(): void {
    if (treeFleet) {
      setName(treeFleet.name);
      setDescription(treeFleet.description);
      setLocation(treeFleet.lat && treeFleet.lng ? { lat: treeFleet.lat, lng: treeFleet.lng } : undefined);
      setTimeZoneFromList(treeFleet.timezone);
    } else {
      setName('');
      setDescription('');
      setLocation(undefined);
      setTimeZoneFromList(null);
    }
  }

  async function updateAddress(location: LngLatType): Promise<void> {
    const ad = await reverseGeocoding(location);
    setAddress(ad);
  }

  if (parent === undefined) {
    return <></>;
  }

  if (treeFleet === undefined) {
    return <></>;
  }

  return (
    <AddEditDialog
      title='fleet'
      parentName={parent.name}
      open={props.open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          const result = await props.editFleet(treeFleet, {
            name,
            description,
            lat,
            lng,
            address,
            timezone: timeZoneFromLocation ? timeZoneFromLocation : timeZoneFromList ? timeZoneFromList : '',
          });
          if (!result) {
            resetValues();
          }
        } else {
          resetValues();
        }
        props.close();
      }}
      mode={'Edit'}
      okDisabled={name === '' || !((location && timeZoneFromLocation) || timeZoneFromList)}
      size='lg'
    >
      <Stack direction='row' spacing={2} sx={{ height: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '35%', height: '100%' }}>
          <InputItem label='Name' value={name} updateValue={setName} />
          <InputLongText label='Description' value={description} updateValue={setDescription} />
          {address && <Typography variant='tableText'>{`Address: ${address}`}</Typography>}
          {location && <Typography variant='tableText'>{prettifyLocation(location)}</Typography>}
          {timeZoneFromLocation && <Typography variant='tableText'>{timeZoneFromLocation}</Typography>}
          <InputAutocomplete
            label='Time Zone'
            value={timeZoneFromList || timeZonesNames[0]}
            items={timeZonesNames}
            updateValue={setTimeZoneFromList}
            disabled={location !== undefined ? true : undefined}
          />
        </Box>
        <AddEditFleetMap
          fleetLocation={location}
          updateLocation={(location?: LngLatType): void => {
            setLocation(location);
          }}
        />
      </Stack>
    </AddEditDialog>
  );
}
