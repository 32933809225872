import useSWR, { SWRResponse } from 'swr';
import { dataFetcher } from '../utils/dataFetcher';
import { BackendError } from '../utils/BackendError';
import { getSwrRevalidationOptions } from './getSwrRevalidationOptions';

export function getUseSwr<Type>(fullUrl: string | null, accessToken: string | null): SWRResponse<Type, BackendError> {
  const urlToUse = accessToken ? fullUrl : null;
  const dataFetcherToUse = accessToken ? dataFetcher<Type>(accessToken) : null;
  return useSWR<Type, BackendError>(urlToUse, dataFetcherToUse, getSwrRevalidationOptions(fullUrl ?? ''));
}
