import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { TreeItemType } from '../../model/frontendDataModels';
import Icon from './Icon';
import { Paper, Typography, useTheme } from '@mui/material';
import ExpandViewSwitch from './ExpandViewSwitch';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import SearchableTree from './searchableTree/SearchableTree';

type Size = {
  width?: number;
  height?: number;
};

type FleetBrowserProps = {
  customerId: string;
  includeUnassigned: boolean;
  footerComponent?: JSX.Element;
  footerHeight?: number;
  canSelect: (treeItem: TreeItemType) => boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  expandedItems: string[];
  expandItem: (item: string) => void;
  expandItems: (itemIds: string[]) => void;
  collapseItem: (item: string) => void;
};

export default function FleetBrowser(props: FleetBrowserProps): JSX.Element {
  const theme = useTheme();

  const [wideMode, setWideMode] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [{ height }, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const onResize = useDebounceCallback(setSize, 200);
  useResizeObserver({
    ref,
    onResize,
  });

  const widthValue = wideMode ? 605 : 400;

  return (
    <Paper
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        borderRight: '2px solid black',
        flexShrink: 0,
        justifyContent: 'space-between',
        width: widthValue,
        minWidth: widthValue,
        maxWidth: widthValue,
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: (height ?? 0) - (props.footerHeight ?? 0),
        }}
      >
        <Box
          sx={{
            paddingLeft: '24px',
            minHeight: '60px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography
            variant='sectionHeader'
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Icon name='browser' fontSize='small' />
            <Box sx={{ marginLeft: '1em' }}>{'Fleet Browser'}</Box>
          </Typography>
          <ExpandViewSwitch wideMode={wideMode} onChange={setWideMode} />
        </Box>
        <SearchableTree
          customerId={props.customerId}
          canSelect={props.canSelect}
          selectedTreeItems={props.selectedTreeItems}
          setItemSelection={props.setItemSelection}
          includeUnassigned={props.includeUnassigned}
          onlyCustomers={false}
          wideMode={wideMode}
          expandedItems={props.expandedItems}
          expandItem={props.expandItem}
          expandItems={props.expandItems}
          collapseItem={props.collapseItem}
        />
      </Box>
      {props.footerComponent && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: props.footerHeight || 0,
          }}
        >
          {props.footerComponent}
        </Box>
      )}
    </Paper>
  );
}
