import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';
import {
  DeviceItemType,
  isCustomerItem,
  isDeviceItem,
  isFleetgroupItem,
  isFleetItem,
  TreeItemType,
} from '../../../../model/frontendDataModels';
import { deleteCustomer } from '../../../../services/customerManipulation';
import { deleteDevice } from '../../../../services/deviceManipulation';
import { deleteFleetgroup } from '../../../../services/fleetgroupManipulation';
import { deleteFleet } from '../../../../services/fleetManipulation';
import { BackendError } from '../../../../utils/BackendError';
import DeleteDialog from '../../../common/DeleteDialog';
import ArrangeButton from '../common/ArrangeButton';
import { mutateCustomers, useCustomer } from '../../../../dataHooks/customerHooks';
import { mutateFleetgroups, mutateFleets, useFleet, useFleetgroup } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import useSWR from 'swr';
import { mutateDevices } from '../../../../dataHooks/deviceHooks';
import { useUser } from '../../../../dataHooks/adminHooks';
import {
  BackendCustomer,
  BackendFleet,
  BackendFleetgroup,
  isBackendCustomer,
  isBackendFleet,
  isBackendFleetgroup,
} from '../../../../model/backendDataModels';
import { getSwrRevalidationOptions } from '../../../../utils/getSwrRevalidationOptions';

type DeleteButtonProps = {
  selectedTreeItems: TreeItemType[];
  clearSelection: () => void;
};

export default function DeleteButton(props: DeleteButtonProps): JSX.Element | null {
  const selectedTreeItem = props.selectedTreeItems.length === 1 ? props.selectedTreeItems[0] : undefined;
  const selectedDevices: DeviceItemType[] = props.selectedTreeItems.filter(item => isDeviceItem(item)).map(item => item as DeviceItemType);

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const { data: user, error: userError } = useUser(loginContext.accessToken);
  const { data: selectedItem, error: itemError } = isCustomerItem(selectedTreeItem)
    ? useCustomer(selectedTreeItem.customerId, loginContext.accessToken)
    : isFleetgroupItem(selectedTreeItem)
    ? useFleetgroup(selectedTreeItem.fleetgroupId, loginContext.accessToken)
    : isFleetItem(selectedTreeItem)
    ? useFleet(selectedTreeItem.fleetId, loginContext.accessToken)
    : useSWR<undefined, BackendError>(null, null, getSwrRevalidationOptions(''));

  if (userError) {
    appContext.addBackendError(userError);
  }

  if (itemError) {
    appContext.addBackendError(itemError);
  }

  async function deleteTreeItem(item: BackendCustomer | BackendFleetgroup | BackendFleet): Promise<void> {
    if (loginContext.accessToken) {
      if (isBackendCustomer(item)) {
        if (await deleteCustomer(item.customerId, loginContext.accessToken, appContext.addBackendError)) {
          mutateCustomers(item.parentId);
          props.clearSelection();
        }
      }

      if (isBackendFleetgroup(item)) {
        if (await deleteFleetgroup(item.fleetgroupId, loginContext.accessToken, appContext.addBackendError)) {
          mutateFleetgroups(item.fleetGroupChildren ? 'fleetgroup' : 'customer', item.parentId);
          props.clearSelection();
        }
      }

      if (isBackendFleet(item)) {
        if (await deleteFleet(item.fleetId, loginContext.accessToken, appContext.addBackendError)) {
          mutateFleets(item.fleetGroupChildren ? 'fleetgroup' : 'customer', item.parentId);
          props.clearSelection();
        }
      }
    } else {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
    }
  }

  async function deleteTreeDevices(devices: DeviceItemType[]): Promise<void> {
    if (loginContext.accessToken) {
      const accessToken = loginContext.accessToken;

      await Promise.all(devices.map(device => deleteDevice(device.mui, accessToken, appContext.addBackendError)));
      await mutateDevices(devices[0].customerId, devices[0].fleetId);
      props.clearSelection();
    } else {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
    }
  }

  return (
    <>
      <ArrangeButton
        title='Delete'
        onClick={(): void => {
          if (selectedTreeItem || selectedDevices.length > 0) {
            setDeletePopupOpen(true);
          }
        }}
        disabled={
          user === undefined ||
          (selectedItem === undefined && selectedDevices.length === 0) ||
          (selectedItem !== undefined &&
            (selectedItem.children === true || (isCustomerItem(selectedTreeItem) && selectedTreeItem.customerId === user.customerId)))
        }
      />
      {selectedItem !== undefined ? (
        <DeleteDialog
          nameToDelete={selectedItem.name}
          close={async (ok: boolean): Promise<void> => {
            if (ok) {
              await deleteTreeItem(selectedItem);
            }
            setDeletePopupOpen(false);
          }}
          open={deletePopupOpen}
        />
      ) : null}
      {selectedDevices.length > 0 ? (
        <DeleteDialog
          nameToDelete={selectedDevices.length > 1 ? `${selectedDevices.length} devices` : `${selectedDevices.length} device`}
          close={async (ok: boolean): Promise<void> => {
            if (ok) {
              await deleteTreeDevices(selectedDevices);
            }
            setDeletePopupOpen(false);
          }}
          open={deletePopupOpen}
        />
      ) : null}
    </>
  );
}
