import React, { useState, useEffect, useContext } from 'react';
import InputItem from '../../../common/InputItem';
import InputLongText from '../../../common/InputLongText';
import AddEditDialog from '../common/AddEditDialog';
import Box from '@mui/material/Box';
import { FleetgroupItemType } from '../../../../model/frontendDataModels';
import { EditFleetgroupData } from '../../../../services/fleetgroupManipulation';
import { AppContext } from '../../../../App';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { useFleetgroup } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import useSWR from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import { BackendFleetgroup } from '../../../../model/backendDataModels';
import { LoginContext } from '../../../../Login';
import { getSwrRevalidationOptions } from '../../../../utils/getSwrRevalidationOptions';

type EditFleetgroupProps = {
  fleetgroup: FleetgroupItemType;
  open: boolean;
  close: () => void;
  updateFleetgroup: (treeFleetgroup: BackendFleetgroup, newData: EditFleetgroupData) => Promise<boolean>;
};

export default function EditFleetgroup({ fleetgroup, open, close, updateFleetgroup }: EditFleetgroupProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const { data: treeFleetgroup, error } = useFleetgroup(fleetgroup.fleetgroupId, loginContext.accessToken);
  const { data: parent, error: parentError } = treeFleetgroup
    ? treeFleetgroup.fleetGroupChildren
      ? useFleetgroup(treeFleetgroup.parentId, loginContext.accessToken)
      : useCustomer(treeFleetgroup.parentId, loginContext.accessToken)
    : useSWR<undefined, BackendError>(null, null, getSwrRevalidationOptions(''));

  if (error) {
    appContext.addBackendError(error);
  }

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  useEffect(() => {
    if (!treeFleetgroup) {
      return;
    }

    setName(treeFleetgroup.name);
    setDescription(treeFleetgroup.description);
  }, [treeFleetgroup]);

  if (!treeFleetgroup) {
    return <></>;
  }

  return (
    <AddEditDialog
      title='fleet group'
      parentName={parent ? parent.name : '-'}
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          const result = await updateFleetgroup(treeFleetgroup, { name, description });
          if (!result) {
            setName(treeFleetgroup.name);
            setDescription(treeFleetgroup.description);
          }
        } else {
          setName(treeFleetgroup.name);
          setDescription(treeFleetgroup.description);
        }
        close();
      }}
      mode={'Edit'}
      size='sm'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
      </Box>
    </AddEditDialog>
  );
}
