import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../common/Icon';
import TabbedPage from '../common/TabbedPage';
import UserHandlingTab from './userHandlingTab/UserHandlingTab';
import { COMPONENT_PADDING } from '../../themes/theme';
import { useUser } from '../../dataHooks/adminHooks';
import { AppContext } from '../../App';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoginContext } from '../../Login';

type Props = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function Admin({ changeHelpBox }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [selectedPage, setSelectedPage] = React.useState<string>('User Handling');

  const { data, error } = useUser(loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  useEffect(() => {
    changeHelpBox(undefined);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: `17px ${COMPONENT_PADDING}px` }}>
        <Typography
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            alignItems: 'center',
          }}
          variant='sectionHeader'
        >
          <Icon name='menu_user' fontSize='medium' />
          <Box sx={{ marginLeft: '1em' }}>Admin</Box>
        </Typography>
      </Box>
      {!data && !error && <LoadingIndicator />}
      {data && !error && (
        <TabbedPage
          pages={[
            {
              name: 'User Handling',
              body: <UserHandlingTab />,
            },
          ]}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      )}
    </Box>
  );
}
