import React, { useState } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import App from './App';
import { AccountInfo } from '@azure/msal-browser';

type LoginContextValues = {
  accessToken: string | null;
  account: AccountInfo | null;
};

export const LoginContext = React.createContext<LoginContextValues>({
  accessToken: null,
  account: null,
});

const loginRequest = {
  scopes: [`api://${process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID}/user_impersonation`],
};

function Login(): JSX.Element {
  const { instance, inProgress } = useMsal();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accessTokenExpires, setAccessTokenExpires] = useState<Date | null>(null);
  const [account, setAccount] = useState<AccountInfo | null>(null);

  async function getAccessToken(): Promise<void> {
    try {
      const allAccounts = instance.getAllAccounts();
      if (Array.isArray(allAccounts) && allAccounts.length > 0) {
        setAccount(allAccounts[0]);

        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: allAccounts[0],
          forceRefresh: true,
        });
        setAccessToken(response.accessToken);
        setAccessTokenExpires(response.expiresOn);
      } else {
        setAccount(null);
        instance.loginRedirect(loginRequest);
      }
    } catch (err) {
      instance.loginRedirect(loginRequest);
      // eslint-disable-next-line no-console
      console.log(`error: ${JSON.stringify(err)}`);
    }
  }

  React.useEffect(() => {
    if (inProgress === 'none') {
      getAccessToken();
    }
  }, [inProgress]);

  React.useEffect(() => {
    const now = Date.now();
    if (accessTokenExpires && accessTokenExpires.getTime() > now) {
      const diffMillis = Math.round((accessTokenExpires.getTime() - now) * 0.5);
      const timeoutId = setTimeout(() => {
        getAccessToken();
      }, diffMillis);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [accessTokenExpires]);

  return (
    <AuthenticatedTemplate>
      <LoginContext.Provider value={{ accessToken, account }}>
        <App />
      </LoginContext.Provider>
    </AuthenticatedTemplate>
  );
}

export default Login;
