import React, { useContext } from 'react';
import ExpandableRow from './ExpandableRow';
import { BackendCustomer } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import { useCustomers } from '../../../dataHooks/customerHooks';
import { useFleetgroups, useFleets } from '../../../dataHooks/fleetAndFleetgroupHooks';
import FleetgroupTree from './FleetgroupTree';
import FleetTree from './FleetTree';
import { isCustomerItem, TreeItemType } from '../../../model/frontendDataModels';
import { LoginContext } from '../../../Login';
import { backendCustomerToItem } from '../../../model/convertModels';

type CustomerTreeProps = {
  customer: BackendCustomer;
  depth: number;
  includeUnassigned: boolean;
  onlyCustomers: boolean;
  wideMode: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  canSelect: (treeItem: TreeItemType) => boolean;
  expandedItems: string[];
  expandItem: (item: string) => void;
  collapseItem: (item: string) => void;
};

export default function CustomerTree(props: CustomerTreeProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const {
    data: customers,
    error: customersError,
    isLoading: loadingCustomers,
  } = useCustomers(props.customer.customerId, loginContext.accessToken);
  const {
    data: fleetgroups,
    error: fleetgroupsError,
    isLoading: loadingFleetgroups,
  } = useFleetgroups('customer', props.customer.customerId, loginContext.accessToken);
  const {
    data: fleets,
    error: fleetsError,
    isLoading: loadingFleets,
  } = useFleets('customer', props.customer.customerId, loginContext.accessToken);

  const expanded = props.expandedItems.find(id => id === props.customer.customerId) !== undefined;
  const selectItem = backendCustomerToItem(props.customer);
  const canSelect = props.canSelect(selectItem);
  const selected = props.selectedTreeItems.filter(item => isCustomerItem(item) && item.customerId === props.customer.customerId).length > 0;

  if (customersError) {
    appContext.addBackendError(customersError);
  }

  if (fleetgroupsError) {
    appContext.addBackendError(fleetgroupsError);
  }

  if (fleetsError) {
    appContext.addBackendError(fleetsError);
  }

  function expandTree(): void {
    props.expandItem(props.customer.customerId);
  }

  function collapseTree(): void {
    props.collapseItem(props.customer.customerId);
  }

  return (
    <ExpandableRow
      label={props.customer.name}
      iconName='menu_user'
      depth={props.depth}
      selected={selected}
      select={
        canSelect
          ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (event: React.MouseEvent): void => props.setItemSelection(selectItem, !selected)
          : undefined
      }
      expanded={expanded}
      expandTree={expandTree}
      collapseTree={collapseTree}
      hasWarning={false}
      loading={loadingCustomers || loadingFleetgroups || loadingFleets}
    >
      {!props.onlyCustomers && (
        <>
          {props.includeUnassigned && (
            <FleetTree
              key={'fleet_unassigned'}
              fleet={{
                address: '',
                description: '',
                fleetGroupChildren: false,
                fleetId: 'unassigned',
                lat: null,
                lng: null,
                name: '[unassigned]',
                parentId: props.customer.customerId,
                timezone: '',
              }}
              parentName={props.customer.name}
              customerId={props.customer.customerId}
              depth={props.depth + 1}
              unassigned={props.includeUnassigned}
              wideMode={props.wideMode}
              selectedTreeItems={props.selectedTreeItems}
              setItemSelection={props.setItemSelection}
              canSelect={props.canSelect}
              expandedItems={props.expandedItems}
              expandItem={props.expandItem}
              collapseItem={props.collapseItem}
            />
          )}
        </>
      )}
      {!props.onlyCustomers &&
        fleets &&
        fleets.map((fleet, index) => {
          return (
            <FleetTree
              key={`fleet_${index}`}
              fleet={fleet}
              parentName={props.customer.name}
              customerId={props.customer.customerId}
              depth={props.depth + 1}
              unassigned={props.includeUnassigned}
              wideMode={props.wideMode}
              selectedTreeItems={props.selectedTreeItems}
              setItemSelection={props.setItemSelection}
              canSelect={props.canSelect}
              expandedItems={props.expandedItems}
              expandItem={props.expandItem}
              collapseItem={props.collapseItem}
            />
          );
        })}
      {customers
        ? customers.map((customer, index) => (
            <CustomerTree
              key={`customer_${index}`}
              customer={customer}
              depth={props.depth + 1}
              includeUnassigned={props.includeUnassigned}
              onlyCustomers={props.onlyCustomers}
              wideMode={props.wideMode}
              selectedTreeItems={props.selectedTreeItems}
              setItemSelection={props.setItemSelection}
              canSelect={props.canSelect}
              expandedItems={props.expandedItems}
              expandItem={props.expandItem}
              collapseItem={props.collapseItem}
            />
          ))
        : null}
      {!props.onlyCustomers &&
        fleetgroups &&
        fleetgroups.map((fleetgroup, index) => (
          <FleetgroupTree
            key={`fleetgroup_${index}`}
            fleetgroup={fleetgroup}
            customerId={props.customer.customerId}
            depth={props.depth + 1}
            unassigned={props.includeUnassigned}
            wideMode={props.wideMode}
            selectedTreeItems={props.selectedTreeItems}
            setItemSelection={props.setItemSelection}
            canSelect={props.canSelect}
            expandedItems={props.expandedItems}
            expandItem={props.expandItem}
            collapseItem={props.collapseItem}
          />
        ))}
    </ExpandableRow>
  );
}
