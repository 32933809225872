import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest, sendPostRequestWithReturnType } from '../utils/dataSenders';

type AddFleetgroupReturnType = {
  fleetgroupId: string;
};

export type AddFleetgroupData = {
  name: string;
  description: string;
  isParentFleetgroup: boolean;
  parentId: string;
};

export type EditFleetgroupData = {
  name: string;
  description: string;
};

export type FleetgroupChangeParentData = {
  parentId: string;
  isParentFleetgroup: boolean;
};

export async function addFleetgroup(
  data: AddFleetgroupData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<AddFleetgroupReturnType | undefined> {
  try {
    const url = '/fleetgroup/addNew';
    return await sendPostRequestWithReturnType<AddFleetgroupData, AddFleetgroupReturnType>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return undefined;
  }
}

export async function editFleetgroup(
  id: string,
  data: EditFleetgroupData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/fleetgroup/${id}/edit`;
    return await sendPostRequest<EditFleetgroupData>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deleteFleetgroup(id: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = `/fleetgroup/${id}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function fleetgroupChangeParent(
  fleetgroupId: string,
  data: FleetgroupChangeParentData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/fleetgroup/${fleetgroupId}/changeParent`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
