import React, { useContext } from 'react';
import EqualizingTab from './equalizingTab/EqualizingTab';
import AlarmsTab from './alarmsTab/AlarmsTab';
import OverviewTab from './OverviewTab';
import SOCTab from './SOCTab';
import ChargingTab from './ChargingTab';
import AddTab from './addTab/AddTab';
import TabbedPage from '../../../common/TabbedPage';
import Icon from '../../../common/Icon';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { useListTabs } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { addAndModifyTabs } from '../../../../services/fleetAndFleetgroupManipulation';
import { BackendFleet, BackendFleetgroup, isBackendFleet, ListTabs } from '../../../../model/backendDataModels';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import { useUser } from '../../../../dataHooks/adminHooks';

type BuildPagesParams = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  listTabs: ListTabs;
  updateAlarms: (active: boolean) => Promise<void>;
  updateStateOfCharge: (active: boolean) => Promise<void>;
  updateCharging: (active: boolean, chargingPerWeek: number[] | null) => Promise<void>;
  updateEqualizing: (active: boolean, equalizingCycles: number | null) => Promise<void>;
  expanded: boolean;
  admin: boolean;
};

function buildTabPages({
  selectedFleetOrFleetgroup,
  listTabs,
  updateAlarms,
  updateStateOfCharge,
  updateCharging,
  updateEqualizing,
  expanded,
  admin,
}: BuildPagesParams): {
  name: string;
  icon: JSX.Element;
  body: JSX.Element;
}[] {
  const tabPages = [
    { name: 'Overview', icon: <Icon name='overview' />, body: <OverviewTab fleetOrFleetgroup={selectedFleetOrFleetgroup} /> },
  ];
  if (listTabs.alarms) {
    tabPages.push({
      name: 'Alarms',
      icon: <Icon name='alarms' />,
      body: (
        <AlarmsTab selectedFleetOrFleetgroup={selectedFleetOrFleetgroup} deleteTab={async (): Promise<void> => await updateAlarms(false)} />
      ),
    });
  }
  if (listTabs.charging) {
    tabPages.push({
      name: 'Charging',
      icon: <Icon name='charging' />,
      body: (
        <ChargingTab
          selectedFleetOrFleetgroup={selectedFleetOrFleetgroup}
          deleteTab={async (): Promise<void> => await updateCharging(false, null)}
        />
      ),
    });
  }
  if (listTabs.equalizing) {
    tabPages.push({
      name: 'Equalizing',
      icon: <Icon name='eq' />,
      body: (
        <EqualizingTab
          selectedFleetOrFleetgroup={selectedFleetOrFleetgroup}
          deleteTab={async (): Promise<void> => await updateEqualizing(false, null)}
        />
      ),
    });
  }
  if (listTabs.soc) {
    tabPages.push({
      name: 'SOC',
      icon: <Icon name='soc' />,
      body: (
        <SOCTab
          selectedFleetOrFleetgroup={selectedFleetOrFleetgroup}
          deleteTab={async (): Promise<void> => await updateStateOfCharge(false)}
        />
      ),
    });
  }

  if (admin) {
    tabPages.push({
      name: '',
      icon: <Icon name='add' />,
      body: (
        <AddTab
          listTabs={listTabs}
          updateAlarms={updateAlarms}
          updateCharging={updateCharging}
          updateEqualizing={updateEqualizing}
          updateStateOfCharge={updateStateOfCharge}
          expanded={expanded}
        />
      ),
    });
  }

  return tabPages;
}

type Props = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  expanded: boolean;
};

export default function FleetOrGroupMonitoring({ selectedFleetOrFleetgroup, expanded }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);
  const { data: user, error: userError } = useUser(loginContext.accessToken);

  const [selectedPage, setSelectedPage] = React.useState<string>('Overview');

  const {
    data: listTabs,
    error: listTabsError,
    mutate,
  } = useListTabs(
    isBackendFleet(selectedFleetOrFleetgroup) ? 'fleet' : 'fleetgroup',
    isBackendFleet(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetId : selectedFleetOrFleetgroup.fleetgroupId,
    loginContext.accessToken
  );

  if (userError) {
    appContext.addBackendError(userError);
  }
  if (listTabsError) {
    appContext.addBackendError(listTabsError);
  }

  if (!listTabs || !user) {
    return listTabsError || userError ? <></> : <LoadingIndicator />;
  }

  type UpdateTabStatusParams = {
    monitoringType: 'charging' | 'equalizing' | 'soc' | 'alarms';
    active: boolean;
    equalizingCycles: number | null;
    chargingPerWeek: number[] | null;
  };

  async function updateTabStatus({ monitoringType, active, equalizingCycles, chargingPerWeek }: UpdateTabStatusParams): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (!listTabs) {
      return;
    }

    const newListTabs: ListTabs = {
      ...listTabs,
      [monitoringType]: active,
      equalizingCycles: monitoringType === 'equalizing' ? equalizingCycles : listTabs.equalizingCycles,
      chargingPerWeek: monitoringType === 'charging' ? chargingPerWeek : listTabs.chargingPerWeek,
    };

    await addAndModifyTabs(
      isBackendFleet(selectedFleetOrFleetgroup) ? 'fleet' : 'fleetgroup',
      isBackendFleet(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetId : selectedFleetOrFleetgroup.fleetgroupId,
      newListTabs,
      loginContext.accessToken,
      mutate,
      appContext.addBackendError
    );
  }

  async function updateAlarms(active: boolean): Promise<void> {
    await updateTabStatus({ monitoringType: 'alarms', active, equalizingCycles: null, chargingPerWeek: null });
  }

  async function updateStateOfCharge(active: boolean): Promise<void> {
    await updateTabStatus({ monitoringType: 'soc', active, equalizingCycles: null, chargingPerWeek: null });
  }

  async function updateCharging(active: boolean, chargingPerWeek: number[] | null): Promise<void> {
    await updateTabStatus({ monitoringType: 'charging', active, equalizingCycles: null, chargingPerWeek });
  }

  async function updateEqualizing(active: boolean, equalizingCycles: number | null): Promise<void> {
    await updateTabStatus({ monitoringType: 'equalizing', active, equalizingCycles, chargingPerWeek: null });
  }

  const pages = buildTabPages({
    selectedFleetOrFleetgroup,
    listTabs,
    updateAlarms,
    updateStateOfCharge,
    updateCharging,
    updateEqualizing,
    expanded,
    admin: !user.roleId.toString().startsWith('2'),
  });

  return <TabbedPage pages={pages} short={!expanded} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />;
}
