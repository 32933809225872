import React, { useContext } from 'react';
import BarGraph from './BarGraph';
import { useAlarmsAggregated } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import ComponentBase from '../../componentBase/ComponentBase';
import Box from '@mui/material/Box';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { AppContext } from '../../../../../App';
import { AggregatedAlarms, BackendFleet, BackendFleetgroup, isBackendFleetgroup } from '../../../../../model/backendDataModels';
import moment from 'moment';
import { LoginContext } from '../../../../../Login';

type Props = {
  title: string;
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  startDate: Date;
  endDate: Date;
  help?: React.ReactNode;
};

type AlarmWithQuantity = {
  name: string;
  quantity: number;
};

function getMerged(alarmsData: AggregatedAlarms): AlarmWithQuantity[] {
  const merged: AlarmWithQuantity[] = alarmsData.alarmNames.map(function (e, i) {
    return { name: e, quantity: alarmsData.quantity[i] };
  });

  return merged;
}

function getSorted(alarmsData: AlarmWithQuantity[]): AlarmWithQuantity[] {
  return alarmsData.sort((n1, n2) => {
    return n1.name > n2.name ? 1 : -1;
  });
}

export default function AggregatedAlarmsGraph({ title, selectedFleetOrFleetgroup, startDate, endDate, help }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const startInteval = moment(startDate).startOf('day');
  const endInterval = moment(endDate).endOf('day');

  const { data: aggregatedAlarms, error } = useAlarmsAggregated(
    isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
    isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
    startInteval.toDate(),
    endInterval.toDate(),
    loginContext.accessToken
  );

  if (error) {
    appContext.addBackendError(error);
  }

  const merged = aggregatedAlarms ? getMerged(aggregatedAlarms) : undefined;
  const sortedAndMerged = merged ? getSorted(merged) : undefined;

  return (
    <ComponentBase title={title} help={help}>
      {sortedAndMerged && !error && (
        <Box sx={{ minHeight: '400px', paddingTop: '1em' }}>
          <BarGraph
            data={{
              categories: sortedAndMerged.map((alarmWithQuantity: AlarmWithQuantity) => {
                return alarmWithQuantity.name;
              }),
              values: sortedAndMerged.map((alarmWithQuantity: AlarmWithQuantity) => {
                return alarmWithQuantity.quantity;
              }),
            }}
          />
        </Box>
      )}
      {!sortedAndMerged && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
