import { SWRResponse } from 'swr';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { MaintenanceInfo } from '../model/backendDataModels';
import { getUseSwr } from '../utils/getUseSwr';

export function useMaintenance(customerId: string | undefined, accessToken: string | null): SWRResponse<MaintenanceInfo, BackendError> {
  const fullUrl = customerId ? getFullUrl(`/customer/${customerId}/maintenance`) : null;

  return getUseSwr<MaintenanceInfo>(fullUrl, accessToken);
}
