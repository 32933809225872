import React, { useContext } from 'react';
import ExpandableRow from './ExpandableRow';
import { BackendDevice, BackendFleet } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import { useDevices } from '../../../dataHooks/deviceHooks';
import { isFleetItem, TreeItemType, isUnassignedFleetItem } from '../../../model/frontendDataModels';
import DeviceList from './deviceList/DeviceList';
import GatewayList from './gatewayList/GatewayList';
import { LoginContext } from '../../../Login';
import { backendFleetToItem, backendUnassignedFleetToItem } from '../../../model/convertModels';

type FleetTreeProps = {
  fleet: BackendFleet;
  customerId: string;
  parentName: string;
  depth: number;
  unassigned: boolean;
  wideMode: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  canSelect: (treeItem: TreeItemType) => boolean;
  expandedItems: string[];
  expandItem: (item: string) => void;
  collapseItem: (item: string) => void;
};

function isGateway(device: BackendDevice): boolean {
  return device.productCategory === 'gateway';
}

function isCharger(device: BackendDevice): boolean {
  return device.productCategory === 'charger';
}

function isBattery(device: BackendDevice): boolean {
  return device.productCategory === 'bmu';
}

export default function FleetTree(props: FleetTreeProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: devices, error: devicesError, isLoading } = useDevices(props.customerId, props.fleet.fleetId, loginContext.accessToken);

  const unassigned = props.fleet.fleetId === 'unassigned';
  const expanded = unassigned
    ? props.expandedItems.find(id => id === `${props.fleet.fleetId}_${props.customerId}`) !== undefined
    : props.expandedItems.find(id => id === props.fleet.fleetId) !== undefined;
  const fleetItem = backendFleetToItem(props.fleet, props.customerId);
  const unassignedItem = backendUnassignedFleetToItem(props.fleet, props.parentName);
  const selectItem = unassigned ? unassignedItem : fleetItem;
  const canSelect = props.canSelect(selectItem);
  const selected = unassigned
    ? props.selectedTreeItems.filter(item => isUnassignedFleetItem(item) && item.customerId === props.fleet.parentId).length > 0
    : props.selectedTreeItems.filter(item => isFleetItem(item) && item.fleetId === props.fleet.fleetId).length > 0;

  let batteries: BackendDevice[] = [];
  let chargers: BackendDevice[] = [];
  let gateways: BackendDevice[] = [];

  if (devices) {
    const backendGateways = devices.filter(isGateway);
    const backendChargers = devices.filter(isCharger);
    const backendBatteries = devices.filter(isBattery);

    gateways = backendGateways;
    chargers = backendChargers;
    batteries = backendBatteries;
  }

  if (devicesError) {
    appContext.addBackendError(devicesError);
  }

  function expandTree(): void {
    if (unassigned) {
      props.expandItem(`${props.fleet.fleetId}_${props.customerId}`);
    } else {
      props.expandItem(props.fleet.fleetId);
    }
  }

  function collapseTree(): void {
    if (unassigned) {
      props.collapseItem(`${props.fleet.fleetId}_${props.customerId}`);
    } else {
      props.collapseItem(props.fleet.fleetId);
    }
  }

  return (
    <ExpandableRow
      label={props.fleet.name}
      iconName={props.fleet.lat && props.fleet.lng ? 'fleet' : 'fleetnolocation'}
      depth={props.depth}
      selected={selected}
      select={
        canSelect
          ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (event: React.MouseEvent): void => props.setItemSelection(selectItem, !selected)
          : undefined
      }
      expanded={expanded}
      expandTree={expandTree}
      collapseTree={collapseTree}
      onlineStatus={gateways.length === 0 ? undefined : gateways.findIndex(gateway => gateway.state === 'Offline') === -1}
      hasWarning={devices !== undefined && devices.findIndex(d => d.warning) !== -1}
      loading={isLoading}
    >
      {chargers.length > 0 && (
        <DeviceList
          name='Chargers'
          suffix='chargers'
          iconName='charger'
          fleetId={props.fleet.fleetId}
          customerId={props.customerId}
          devices={chargers}
          depth={props.depth + 1}
          unassigned={props.unassigned}
          wideMode={props.wideMode}
          selectedTreeItems={props.selectedTreeItems}
          setItemSelection={props.setItemSelection}
          loading={isLoading}
          expandedItems={props.expandedItems}
          expandItem={props.expandItem}
          collapseItem={props.collapseItem}
        />
      )}

      {batteries.length > 0 && (
        <DeviceList
          name='Batteries'
          suffix='batteries'
          iconName='battery'
          fleetId={props.fleet.fleetId}
          customerId={props.customerId}
          devices={batteries}
          depth={props.depth + 1}
          unassigned={props.unassigned}
          wideMode={props.wideMode}
          selectedTreeItems={props.selectedTreeItems}
          setItemSelection={props.setItemSelection}
          loading={isLoading}
          expandedItems={props.expandedItems}
          expandItem={props.expandItem}
          collapseItem={props.collapseItem}
        />
      )}
      {gateways.length > 0 && (
        <GatewayList
          fleetId={props.fleet.fleetId}
          customerId={props.customerId}
          gateways={gateways}
          depth={props.depth + 1}
          unassigned={props.unassigned}
          wideMode={props.wideMode}
          selectedTreeItems={props.selectedTreeItems}
          setItemSelection={props.setItemSelection}
          loading={isLoading}
          expandedItems={props.expandedItems}
          expandItem={props.expandItem}
          collapseItem={props.collapseItem}
        />
      )}
    </ExpandableRow>
  );
}
