import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { AppContext } from '../../../../App';
import { useBmuOverviewStatus } from '../../../../dataHooks/bmuHooks';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';
import { BackendDevice } from '../../../../model/backendDataModels';
import { LoginContext } from '../../../../Login';

type Props = {
  selectedDevice: BackendDevice;
};

export default function BmuOverviewStatus({ selectedDevice }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const { data: bmuOverviewStatus, error } = useBmuOverviewStatus(selectedDevice.mui, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  if (!bmuOverviewStatus) {
    return (
      <>
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Status
        </Typography>

        {!error && <LoadingIndicator />}
      </>
    );
  }

  const timestampDate = moment(bmuOverviewStatus.timestamp, moment.ISO_8601).tz(state.timezone);
  const timestamp = timestampDate.format('YYYY-MM-DD HH:mm:ss');
  const headerStyle = { margin: '0 22px 22px 0' };
  const minWidthStyle = { margin: '0 22px 22px 0', minWidth: '5em' };

  return (
    <>
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        Status
      </Typography>

      {!error && (
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
          <DeviceHeaderInfo
            sx={minWidthStyle}
            label='State'
            text={<Box sx={{ fontWeight: 'bold' }}>{bmuOverviewStatus.state}</Box>}
            textColor='info'
          />
          <DeviceHeaderInfo sx={minWidthStyle} label='SOC' text={bmuOverviewStatus.soc} />
          <DeviceHeaderInfo sx={minWidthStyle} label='SOH' text={bmuOverviewStatus.soh} textColor='success' />
          <DeviceHeaderInfo sx={headerStyle} label='Charging curve' text={bmuOverviewStatus.chargingCurve} />
          <DeviceHeaderInfo sx={headerStyle} label='Active alarms' text={bmuOverviewStatus.activeAlarms} />
          <DeviceHeaderInfo sx={headerStyle} label='Timestamp' text={timestamp} />
        </Box>
      )}
    </>
  );
}
