import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Icon from '../../../common/Icon';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';
import FleetOrGroupHeaderInfo from './FleetOrGroupHeaderInfo';
import { LngLatType } from '../../../arrange/addEditDeleteFooter/common/addEditFleet/AddEditFleetMap';
import reverseGeocoding from '../../../../services/reverseGeocoding';
import { BackendFleet, BackendFleetgroup, isBackendFleet } from '../../../../model/backendDataModels';
import { useFleetgroup, useFleetgroupInfo, useFleetInfo } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';

type FleetInfoProps = {
  fleet: BackendFleet;
};

function FleetInfo(props: FleetInfoProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [address, setAddress] = useState<string | null>(null);

  const { data: parent, error: parentError } = props.fleet.fleetGroupChildren
    ? useFleetgroup(props.fleet.parentId, loginContext.accessToken)
    : useCustomer(props.fleet.parentId, loginContext.accessToken);
  const { data: info, error: infoError } = useFleetInfo(props.fleet.fleetId, loginContext.accessToken);

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  if (infoError) {
    appContext.addBackendError(infoError);
  }

  async function updateAddress(location: LngLatType): Promise<void> {
    const ad = await reverseGeocoding(location);
    setAddress(ad);
  }

  useEffect(() => {
    if (props.fleet.lat && props.fleet.lng) {
      updateAddress({ lat: props.fleet.lat, lng: props.fleet.lng });
    }
  }, [props.fleet]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      <Typography variant='bigHeader'>{props.fleet.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px', marginBottom: '31px' }}>
        <FleetOrGroupHeaderInfo label='Location' text={address || 'N/A'} />
        {parent &&
          (props.fleet.fleetGroupChildren ? (
            <FleetOrGroupHeaderInfo label='Fleet Group' text={parent.name} />
          ) : (
            <FleetOrGroupHeaderInfo label='Customer' text={parent.name} />
          ))}
        {info && <FleetOrGroupHeaderInfo label='Total Devices' text={info.devices - (info.bms ?? 0)} />}
      </Box>
    </Box>
  );
}

type FleetgroupInfoProps = {
  fleetgroup: BackendFleetgroup;
};

function FleetgroupInfo(props: FleetgroupInfoProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: parent, error: parentError } = props.fleetgroup.fleetGroupChildren
    ? useFleetgroup(props.fleetgroup.parentId, loginContext.accessToken)
    : useCustomer(props.fleetgroup.parentId, loginContext.accessToken);

  const { data: info, error: infoError } = useFleetgroupInfo(props.fleetgroup.fleetgroupId, loginContext.accessToken);

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  if (infoError) {
    appContext.addBackendError(infoError);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      <Typography variant='bigHeader'>{props.fleetgroup.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px', marginBottom: '31px' }}>
        {parent &&
          (props.fleetgroup.fleetGroupChildren ? (
            <FleetOrGroupHeaderInfo label='Fleet Group' text={parent.name} />
          ) : (
            <FleetOrGroupHeaderInfo label='Customer' text={parent.name} />
          ))}
        {info && <FleetOrGroupHeaderInfo label='Total Devices' text={info.devices - (info.bms ?? 0)} />}
      </Box>
    </Box>
  );
}

type SelectedFleetOrGroupHeaderProps = {
  fleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  expanded: boolean;
  changeExpanded: (expanded: boolean) => void;
};

export default function SelectedFleetOrGroupHeader({
  fleetOrFleetgroup,
  expanded,
  changeExpanded,
}: SelectedFleetOrGroupHeaderProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: '24px' }}>
      <Box
        sx={{
          minHeight: '65px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography
          variant='sectionHeader'
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon name={isBackendFleet(fleetOrFleetgroup) ? 'fleet' : 'fleetgroup'} />
          <Box sx={{ marginLeft: '1em' }}>{isBackendFleet(fleetOrFleetgroup) ? 'Fleet' : 'Fleet Group'}</Box>
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <ExpandViewSwitch wideMode={expanded} onChange={(expanded): void => changeExpanded(expanded)} />
        </Box>
      </Box>
      {isBackendFleet(fleetOrFleetgroup) ? <FleetInfo fleet={fleetOrFleetgroup} /> : <FleetgroupInfo fleetgroup={fleetOrFleetgroup} />}
    </Box>
  );
}
