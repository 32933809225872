import React, { useContext, useState } from 'react';
import InputItem from '../../../common/InputItem';
import InputLongText from '../../../common/InputLongText';
import AddEditDialog from '../common/AddEditDialog';
import Box from '@mui/material/Box';
import { AddFleetgroupData } from '../../../../services/fleetgroupManipulation';
import { CustomerItemType, FleetgroupItemType, isCustomerItem, isFleetgroupItem } from '../../../../model/frontendDataModels';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { useFleetgroup } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';

type AddFleetgroupProps = {
  parent: CustomerItemType | FleetgroupItemType;
  open: boolean;
  close: () => void;
  addNewFleetgroup: (newFleetgroup: AddFleetgroupData) => Promise<void>;
};

export default function AddFleetgroup(props: AddFleetgroupProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const { data: parent, error } = isCustomerItem(props.parent)
    ? useCustomer(props.parent.customerId, loginContext.accessToken)
    : useFleetgroup(props.parent.fleetgroupId, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <AddEditDialog
      title='fleet group'
      open={props.open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          await props.addNewFleetgroup({
            name,
            description,
            parentId: isCustomerItem(props.parent) ? props.parent.customerId : props.parent.fleetgroupId,
            isParentFleetgroup: isFleetgroupItem(props.parent),
          });
        }

        setName('');
        setDescription('');
        props.close();
      }}
      mode={'Add'}
      size='sm'
      parentName={parent?.name ?? ''}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
      </Box>
    </AddEditDialog>
  );
}
