import * as React from 'react';
import Autocomplete, { autocompleteClasses, AutocompleteProps } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { useServiceLogTypes } from '../../../../../services/serviceLogManipulation';
import { LoginContext } from '../../../../../Login';
import { AppContext } from '../../../../../App';

interface EditTypeOption {
  value: string;
}

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: '100%',
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.body2,
    padding: '1px 0',
    height: '100%',
    '& input': {
      padding: '0',
      height: '100%',
    },
  },
})) as typeof Autocomplete;

const StyledPopper = styled(Popper)({
  '& .MuiPaper-root': {
    borderRadius: '0 0 4px 4px',
    backgroundColor: 'black',
  },
});

function EditEventType(props: GridRenderEditCellParams<EditTypeOption>): JSX.Element {
  const loginContext = React.useContext(LoginContext);
  const appContext = React.useContext(AppContext);

  const apiRef = useGridApiContext();

  const {
    data: serviceLogTypes,
    error: serviceLogTypesError,
    isLoading: serviceLogTypesIsLoading,
  } = useServiceLogTypes(loginContext.accessToken);

  if (serviceLogTypesError) {
    appContext.addBackendError(serviceLogTypesError);
  }

  const handleChange = React.useCallback<NonNullable<AutocompleteProps<EditTypeOption, false, true, false>['onChange']>>(
    async (event, newValue) => {
      await apiRef.current.setEditCellValue({ id: props.id, field: props.field, value: newValue.value }, event);
      apiRef.current.stopCellEditMode({ id: props.id, field: props.field });
    },
    [apiRef, props.field, props.id]
  );

  const optionTypes = (serviceLogTypes ?? []).map(type => ({ value: type }));

  return (
    <StyledAutocomplete<EditTypeOption, false, true, false>
      value={{ value: props.value }}
      onChange={handleChange}
      options={optionTypes}
      loading={serviceLogTypesIsLoading}
      getOptionLabel={(option: EditTypeOption): string => option.value}
      autoHighlight
      fullWidth
      disableClearable
      renderOption={(optionProps, option: EditTypeOption): React.ReactNode => (
        <Box
          component='li'
          sx={{
            font: '400 0.875rem Roboto, Helvetica, Arial, sans-serif',
          }}
          {...optionProps}
          key={option.value}
        >
          {option.value}
        </Box>
      )}
      renderInput={(params): React.ReactNode => (
        <InputBase
          autoFocus
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
          {...params.InputProps}
        />
      )}
      PopperComponent={StyledPopper}
    />
  );
}

export function renderEditEventType(params: GridRenderEditCellParams<EditTypeOption>): JSX.Element {
  return <EditEventType {...params} />;
}
