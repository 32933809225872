import * as React from 'react';
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '../../../common/commonTypes';
import { SxProps as MuiSxProps } from '@mui/material';
import { isToday, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useContext } from 'react';
import Store from '../../../../store/Store';

function Padding({ sx }: SxProps): JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: '25%',
        display: 'flex',
        width: '40px',
        height: '40px',
        ...sx,
      }}
    />
  );
}

function getSpecialSx(
  timeZone: string,
  date?: Date,
  quantity?: number | null
): { specialSx: MuiSxProps; label?: number; showQuantity: boolean } {
  const theme = useTheme();
  const dateToday = utcToZonedTime(new Date(), timeZone);

  if (date === undefined) {
    return { specialSx: { backgroundColor: theme.palette.background.default }, label: undefined, showQuantity: false };
  } else if (quantity === undefined || isAfter(date, dateToday)) {
    return {
      specialSx: { borderStyle: 'dashed', borderWidth: 'thin', borderColor: theme.palette.action.active },
      label: date && date.getDate(),
      showQuantity: false,
    };
  } else if (isToday(date)) {
    return {
      specialSx: { backgroundColor: theme.palette.info.main },
      label: date && date.getDate(),
      showQuantity: false,
    };
  } else {
    return {
      specialSx: { backgroundColor: theme.palette.action.active },
      label: date && date.getDate(),
      showQuantity: true,
    };
  }
}

type DayLabelProps = {
  icon: JSX.Element;
  label?: number;
  quantity?: number | null;
};

function DayLabel({ icon, label, quantity }: DayLabelProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', position: 'relative', margin: '5px', cursor: 'default' }}>
      {icon}
      {label && (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#D5D5D5',
            fontSize: '12px',
            fontWeight: 'bold',
            width: '40px',
            height: '40px',
          }}
        >
          <Divider sx={{ justifyContent: 'center' }} orientation='vertical' flexItem>
            {quantity ? `${quantity}` : <br />}
            <Box
              sx={{
                color: '#101619',
                fontWeight: 'lighter',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: quantity ? '#101619' : '#151E23',
                  width: '30px',
                  height: '1px',
                }}
              />
              {quantity ? (
                `${label}`
              ) : (
                <Box
                  sx={{
                    color: '#405D6D',
                    fontWeight: 'lighter',
                  }}
                >
                  {label}
                </Box>
              )}
            </Box>
          </Divider>
        </div>
      )}
    </Box>
  );
}

type CalendarQuantityDayProps = {
  date?: Date;
  quantity?: number | null;
};

export default function CalendarQuantityDay({ date, quantity }: CalendarQuantityDayProps): JSX.Element {
  const { state } = useContext(Store);
  const { specialSx, label, showQuantity } = getSpecialSx(state.timezone, date, quantity);

  return <DayLabel icon={<Padding sx={{ ...specialSx }} />} label={label} quantity={showQuantity ? quantity : undefined} />;
}
