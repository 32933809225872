import React from 'react';
import { useChargerOverviewStatus } from '../../../../dataHooks/chargerHooks';
import { useBmsOverviewStatus } from '../../../../dataHooks/bmsHooks';
import { useBmuOverviewStatus } from '../../../../dataHooks/bmuHooks';
import { useBluOverviewStatus } from '../../../../dataHooks/bluHooks';
import CommonHeader from './CommonHeader';
import { BackendDevice } from '../../../../model/backendDataModels';
import GatewayHeader from '../../selectedGatewayView/selectedGatewayHeader/GatewayHeader';

type SelectedDeviceHeaderProps = {
  selectedDevice: BackendDevice;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
  deviceInitialized: boolean;
};

export default function SelectedDeviceHeader(props: SelectedDeviceHeaderProps): JSX.Element {
  if (props.selectedDevice.productCategory === 'charger') {
    return (
      <CommonHeader
        selectedDevice={props.selectedDevice}
        dataHook={useChargerOverviewStatus}
        onFullscreenChanged={props.onFullscreenChanged}
        fullscreen={props.fullscreen}
        deviceInitialized={props.deviceInitialized}
      />
    );
  } else if (props.selectedDevice.productCategory === 'bms') {
    return (
      <CommonHeader
        selectedDevice={props.selectedDevice}
        dataHook={useBmsOverviewStatus}
        onFullscreenChanged={props.onFullscreenChanged}
        fullscreen={props.fullscreen}
        deviceInitialized={props.deviceInitialized}
      />
    );
  } else if (props.selectedDevice.productCategory === 'bmu') {
    if (props.selectedDevice.type.toLowerCase() === 'blu') {
      return (
        <CommonHeader
          selectedDevice={props.selectedDevice}
          dataHook={useBluOverviewStatus}
          onFullscreenChanged={props.onFullscreenChanged}
          fullscreen={props.fullscreen}
          deviceInitialized={props.deviceInitialized}
        />
      );
    } else {
      return (
        <CommonHeader
          selectedDevice={props.selectedDevice}
          dataHook={useBmuOverviewStatus}
          onFullscreenChanged={props.onFullscreenChanged}
          fullscreen={props.fullscreen}
          deviceInitialized={props.deviceInitialized}
        />
      );
    }
  } else if (props.selectedDevice.productCategory === 'gateway') {
    return (
      <GatewayHeader selectedDevice={props.selectedDevice} onFullscreenChanged={props.onFullscreenChanged} fullscreen={props.fullscreen} />
    );
  }

  return <></>;
}
