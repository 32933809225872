import {
  BackendCustomer,
  BackendDevice,
  BackendFleet,
  BackendFleetgroup,
  BackendFleetLocation,
  SearchCustomer,
  SearchDevice,
  SearchFleet,
  SearchFleetgroup,
} from './backendDataModels';
import { CustomerItemType, DeviceItemType, FleetgroupItemType, FleetItemType, UnassignedFleetItemType } from './frontendDataModels';

export function backendDeviceToItem(device: BackendDevice): DeviceItemType {
  return {
    mui: device.mui,
    customerId: device.customerId,
    fleetId: device.fleetId,
    type: 'Device',
  };
}

export function backendCustomerToItem(customer: BackendCustomer): CustomerItemType {
  return {
    customerId: customer.customerId,
    name: customer.name,
    parentId: customer.parentId,
    type: 'Customer',
  };
}

export function backendFleetgroupToItem(fleetgroup: BackendFleetgroup): FleetgroupItemType {
  return {
    fleetgroupId: fleetgroup.fleetgroupId,
    name: fleetgroup.name,
    parentId: fleetgroup.parentId,
    parentType: fleetgroup.fleetGroupChildren ? 'fleetgroup' : 'customer',
    type: 'Fleetgroup',
  };
}

export function backendFleetToItem(fleet: BackendFleet, customerId: string): FleetItemType {
  return {
    fleetId: fleet.fleetId,
    customerId: customerId,
    name: fleet.name,
    parentId: fleet.parentId,
    parentType: fleet.fleetGroupChildren ? 'fleetgroup' : 'customer',
    type: 'Fleet',
  };
}

export function backendUnassignedFleetToItem(fleet: BackendFleet, parentName: string): UnassignedFleetItemType {
  return {
    type: 'FleetUnassigned',
    fleetId: fleet.fleetId,
    customerId: fleet.parentId,
    parentName: parentName,
  };
}

export function searchDeviceToItem(device: SearchDevice): DeviceItemType {
  return {
    mui: device.mui,
    customerId: device.customerId,
    fleetId: device.fleetId,
    type: 'Device',
  };
}

export function searchCustomerToItem(customer: SearchCustomer): CustomerItemType {
  return {
    customerId: customer.customerId,
    name: customer.name,
    parentId: '',
    type: 'Customer',
  };
}

export function searchFleetgroupToItem(fleetgroup: SearchFleetgroup): FleetgroupItemType {
  return {
    fleetgroupId: fleetgroup.fleetgroupId,
    name: fleetgroup.name,
    parentId: '',
    parentType: '',
    type: 'Fleetgroup',
  };
}

export function searchFleetToItem(fleet: SearchFleet, customerId: string): FleetItemType {
  return {
    fleetId: fleet.fleetId,
    customerId: customerId,
    name: fleet.name,
    parentId: '',
    parentType: '',
    type: 'Fleet',
  };
}

export function backendFleetLocationToItem(fleetLocation: BackendFleetLocation): FleetItemType {
  return {
    fleetId: fleetLocation.fleetId,
    customerId: '',
    name: '',
    parentId: '',
    parentType: '',
    type: 'Fleet',
  };
}

export function dummyCustomerItem(): CustomerItemType {
  return {
    type: 'Customer',
    customerId: '',
    name: '',
    parentId: '',
  };
}
