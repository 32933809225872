import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { LoginContext } from '../../../Login';
import { AppContext } from '../../../App';
import GatewayHeader from './selectedGatewayHeader/GatewayHeader';
import GatewayConfigurationTab from './GatewayConfigurationTab';
import TabbedPage from '../../common/TabbedPage';
import EmptyFleetDeviceView from '../selectedDeviceView/EmptyFleetDeviceView';
import { COMPONENT_PADDING, WIDTH_OF_INFO_COMPONENT } from '../../../themes/theme';
import { useTheme } from '@mui/material';
import { useFleet } from '../../../dataHooks/fleetAndFleetgroupHooks';
import { BackendDevice } from '../../../model/backendDataModels';

type EmptyGatewayContextValues = {
  isInitializedGateway: boolean;
  setIsInitializedGateway: (initialized: boolean) => void;
};

export const EmptyGatewayContext = React.createContext<EmptyGatewayContextValues>({
  isInitializedGateway: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setIsInitializedGateway: (initialized: boolean) => {},
});

type SelectedGatewayViewProps = {
  selectedGateway: BackendDevice;
};

function SelectedDeviceView({ selectedGateway }: SelectedGatewayViewProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const [isInitializedGateway, setIsInitializedGateway] = useState<boolean>(true);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<string>('Configuration');

  const { data: fleet, error: fleetError } = useFleet(selectedGateway.fleetId, loginContext.accessToken);

  if (fleetError) {
    appContext.addBackendError(fleetError);
  }

  useEffect(() => {
    // assume gateway is initialized until data provided prove otherwise
    setIsInitializedGateway(true);
  }, [selectedGateway]);

  return (
    <EmptyGatewayContext.Provider value={{ isInitializedGateway, setIsInitializedGateway }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: fullscreen ? '100%' : WIDTH_OF_INFO_COMPONENT * 2 + COMPONENT_PADDING * 2,
          borderRight: '2px solid black',
        }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <GatewayHeader selectedDevice={selectedGateway} onFullscreenChanged={setFullscreen} fullscreen={fullscreen} />
        {fleet && !isInitializedGateway && <EmptyFleetDeviceView />}
        {fleet && (
          <TabbedPage
            tabAlignment='right'
            pages={[{ name: 'Configuration', body: <GatewayConfigurationTab selectedDevice={selectedGateway} /> }]}
            disableScroll={true}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        )}
      </Box>
    </EmptyGatewayContext.Provider>
  );
}

export default React.memo(SelectedDeviceView);
