import React, { useContext } from 'react';
import ExpandableRow from './ExpandableRow';
import { BackendFleetgroup } from '../../../model/backendDataModels';
import { useFleetgroups, useFleets } from '../../../dataHooks/fleetAndFleetgroupHooks';
import { AppContext } from '../../../App';
import FleetTree from './FleetTree';
import { isFleetgroupItem, TreeItemType } from '../../../model/frontendDataModels';
import { LoginContext } from '../../../Login';
import { backendFleetgroupToItem } from '../../../model/convertModels';

type FleetgroupTreeProps = {
  fleetgroup: BackendFleetgroup;
  customerId: string;
  depth: number;
  unassigned: boolean;
  wideMode: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  canSelect: (treeItem: TreeItemType) => boolean;
  expandedItems: string[];
  expandItem: (item: string) => void;
  collapseItem: (item: string) => void;
};

export default function FleetgroupTree(props: FleetgroupTreeProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const {
    data: fleetgroups,
    error: fleetgroupsError,
    isLoading: loadingFleetgroups,
  } = useFleetgroups('fleetgroup', props.fleetgroup.fleetgroupId, loginContext.accessToken);
  const {
    data: fleets,
    error: fleetsError,
    isLoading: loadingFleets,
  } = useFleets('fleetgroup', props.fleetgroup.fleetgroupId, loginContext.accessToken);

  const expanded = props.expandedItems.find(id => id === props.fleetgroup.fleetgroupId) !== undefined;
  const selectItem = backendFleetgroupToItem(props.fleetgroup);
  const canSelect = props.canSelect(selectItem);
  const selected =
    props.selectedTreeItems.filter(item => isFleetgroupItem(item) && item.fleetgroupId === props.fleetgroup.fleetgroupId).length > 0;

  if (fleetgroupsError) {
    appContext.addBackendError(fleetgroupsError);
  }

  if (fleetsError) {
    appContext.addBackendError(fleetsError);
  }

  function expandTree(): void {
    props.expandItem(props.fleetgroup.fleetgroupId);
  }

  function collapseTree(): void {
    props.collapseItem(props.fleetgroup.fleetgroupId);
  }

  return (
    <ExpandableRow
      label={props.fleetgroup.name}
      iconName='fleetgroup'
      depth={props.depth}
      selected={selected}
      select={
        canSelect
          ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (event: React.MouseEvent): void => props.setItemSelection(selectItem, !selected)
          : undefined
      }
      expanded={expanded}
      expandTree={expandTree}
      collapseTree={collapseTree}
      hasWarning={false}
      loading={loadingFleetgroups || loadingFleets}
    >
      {fleetgroups &&
        fleetgroups.map(fleetgroup => (
          <FleetgroupTree
            key={fleetgroup.fleetgroupId}
            fleetgroup={fleetgroup}
            customerId={props.customerId}
            depth={props.depth + 1}
            unassigned={props.unassigned}
            wideMode={props.wideMode}
            selectedTreeItems={props.selectedTreeItems}
            setItemSelection={props.setItemSelection}
            canSelect={props.canSelect}
            expandedItems={props.expandedItems}
            expandItem={props.expandItem}
            collapseItem={props.collapseItem}
          />
        ))}
      {fleets &&
        fleets.map((fleet, index) => {
          return (
            <FleetTree
              key={`fleet_${index}`}
              fleet={fleet}
              parentName={props.fleetgroup.name}
              customerId={props.customerId}
              depth={props.depth + 1}
              unassigned={props.unassigned}
              wideMode={props.wideMode}
              selectedTreeItems={props.selectedTreeItems}
              setItemSelection={props.setItemSelection}
              canSelect={props.canSelect}
              expandedItems={props.expandedItems}
              expandItem={props.expandItem}
              collapseItem={props.collapseItem}
            />
          );
        })}
    </ExpandableRow>
  );
}
