import React, { useContext, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StoreProvider from './store/StoreProvider';
import { COMPONENT_PADDING, getTheme } from './themes/theme';
import LogFilesPage from './components/fleetView/selectedDeviceView/logFilesTab/LogFilesPage';
import Arrange from './components/arrange/Arrange';
import { TreeItemType } from './model/frontendDataModels';
import { BackendError } from './utils/BackendError';
import Admin from './components/admin/Admin';
import SettingsFab from './components/userSetting/UserSettingFab';
import UserProfile from './components/userProfile/UserProfile';
import MaintenanceMessage from './components/userMessage/MaintenanceMessage';
import ErrorMessage from './components/userMessage/ErrorMessage';
import DeckView from './components/DeckView';
import { useMaintenance } from './dataHooks/maintenanceHooks';
import { MaintenanceMessage as MaintenanceInfo } from './model/backendDataModels';
import { AppRoot } from './AppRoot';
import { InitializingView } from './components/fleetView/InitializingView';
import { Portal } from '@mui/material';
import { useUser } from './dataHooks/adminHooks';
import FleetView from './components/fleetView/FleetView';
import { LoginContext } from './Login';

type AppContextValues = {
  addBackendError: (newError: BackendError) => void;
};

export const AppContext = React.createContext<AppContextValues>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addBackendError: (newError: BackendError) => {},
});

function App(): JSX.Element {
  const loginContext = useContext(LoginContext);

  const [selectedTreeItem, setSelectedTreeItem] = useState<TreeItemType | undefined>(undefined);
  const [maintenanceMessage, setMaintenanceMessage] = useState<MaintenanceInfo | null>(null);
  const [backendErrors, setBackendErrors] = useState<BackendError[]>([]);
  const [justRemovedBackendError, setJustRemovedBackendError] = useState<BackendError | undefined>(undefined);
  const [helpBox, setHelpBox] = useState<React.ReactNode>(undefined);
  const [helpBoxVisible, setHelpBoxVisible] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  function expandItem(itemId: string): void {
    setExpandedItems([...expandedItems, itemId]);
  }

  function expandItems(itemIds: string[]): void {
    setExpandedItems([...expandedItems, ...itemIds]);
  }

  function collapseItem(itemId: string): void {
    setExpandedItems(expandedItems.filter(id => id !== itemId));
  }

  function setItemSelection(item: TreeItemType, selected: boolean): void {
    if (selected) {
      setSelectedTreeItem(item);
    } else {
      setSelectedTreeItem(undefined);
    }
  }

  const { data: user, error: userError } = useUser(loginContext.accessToken);
  const { data: maintenance, error: maintenanceError } = useMaintenance(user?.customerId, loginContext.accessToken);

  const helpBoxProps = {
    changeHelpBox: setHelpBox,
    hideHelpBox: (): void => setHelpBoxVisible(false),
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AppRoot
          selectedTreeItem={selectedTreeItem}
          expandItems={expandItems}
          user={user}
          helpBox={helpBox}
          helpBoxVisible={helpBoxVisible}
          setHelpBoxVisible={setHelpBoxVisible}
          setItemSelection={setItemSelection}
        />
      ),
      children: [
        {
          path: '/',
          element: <InitializingView />,
        },
        {
          path: '/view',
          element: user ? (
            <FleetView
              customerId={user.customerId}
              selectedTreeItems={selectedTreeItem === undefined ? [] : [selectedTreeItem]}
              setItemSelection={setItemSelection}
              expandedItems={expandedItems}
              expandItem={expandItem}
              expandItems={expandItems}
              collapseItem={collapseItem}
              {...helpBoxProps}
            />
          ) : null,
        },
        {
          path: '/arrange',
          element: user ? <Arrange customerId={user.customerId} {...helpBoxProps} /> : null,
        },
        {
          path: '/admin',
          element: <Admin {...helpBoxProps} />,
        },
        {
          path: '/profile',
          element: <UserProfile {...helpBoxProps} />,
        },
        {
          path: '/logfiles/:mui',
          element: <LogFilesPage {...helpBoxProps} />,
        },
      ],
    },
  ]);

  if (userError) {
    addBackendError(userError);
  }

  if (maintenanceError) {
    addBackendError(maintenanceError);
  }

  useEffect(() => {
    if (justRemovedBackendError) {
      setTimeout(() => {
        setJustRemovedBackendError(undefined);
      }, 1000);
    }
  }, [justRemovedBackendError]);

  useEffect(() => {
    const maintenanceMessage = maintenance?.message ?? null;

    if (maintenanceMessage !== null) {
      setMaintenanceMessage(maintenanceMessage);
    }
  }, [maintenance, setMaintenanceMessage]);

  function compareBackendErrors(first: BackendError, second: BackendError): boolean {
    return first.message === second.message && first.url === second.url && first.status === second.status;
  }

  function addBackendError(newError: BackendError): void {
    // eslint-disable-next-line no-console
    console.log('BackendError: ', newError);

    if (
      ((justRemovedBackendError && !compareBackendErrors(newError, justRemovedBackendError)) || !justRemovedBackendError) &&
      backendErrors.findIndex(error => compareBackendErrors(error, newError)) === -1
    ) {
      setBackendErrors([...backendErrors, newError]);
    }
  }

  function removeBackendError(): void {
    for (const error of backendErrors) {
      setBackendErrors([]);
      setJustRemovedBackendError(error);
    }
  }

  return (
    <ThemeProvider theme={getTheme}>
      <CssBaseline />
      <StoreProvider>
        <AppContext.Provider
          value={{
            addBackendError,
          }}
        >
          <RouterProvider router={router} />
          <Portal>
            <DeckView
              sx={{
                position: 'absolute',
                right: `${COMPONENT_PADDING * 2}px`,
                bottom: `${COMPONENT_PADDING * 2}px`,
                zIndex: 10,
              }}
            >
              {backendErrors.length > 0 ? <ErrorMessage messages={backendErrors} removeMessage={removeBackendError} /> : null}
              {maintenanceMessage ? (
                <MaintenanceMessage info={maintenanceMessage} removeMessage={(): void => setMaintenanceMessage(null)} />
              ) : null}
              <SettingsFab />
            </DeckView>
          </Portal>
        </AppContext.Provider>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
