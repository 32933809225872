import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { CustomerItemType, isCustomerItem, TreeItemType } from '../../../model/frontendDataModels';
import { ViewWithHeader } from './ViewWithHeader';
import SearchableTree from '../../common/searchableTree/SearchableTree';
import { LoginContext } from '../../../Login';
import { useUser } from '../../../dataHooks/adminHooks';
import { AppContext } from '../../../App';

type Props = {
  selectedCustomers: CustomerItemType[];
  updateSelectCustomers: (selectedCustomers: CustomerItemType[]) => void;
  multiselect?: boolean;
  canSelectCustomer?: (treeCustomer: CustomerItemType) => boolean;
};

export default function CustomerSelector({ selectedCustomers, updateSelectCustomers, canSelectCustomer }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const { data: user, error: userError } = useUser(loginContext.accessToken);

  if (userError) {
    appContext.addBackendError(userError);
  }

  function expandItem(itemId: string): void {
    setExpandedItems([...expandedItems, itemId]);
  }

  function expandItems(items: string[]): void {
    setExpandedItems([...expandedItems, ...items]);
  }

  function collapseItem(itemId: string): void {
    setExpandedItems(expandedItems.filter(id => id !== itemId));
  }

  if (user === undefined) {
    return <></>;
  }

  return (
    <ViewWithHeader
      sx={{
        minWidth: 400,
        borderRight: '2px solid black',
      }}
      header={
        <>
          <Box sx={{ marginLeft: '1em' }}>{'Select Customer'}</Box>
        </>
      }
    >
      <SearchableTree
        customerId={user.customerId}
        canSelect={(treeItem: TreeItemType): boolean => {
          if (isCustomerItem(treeItem)) {
            if (canSelectCustomer) {
              return canSelectCustomer(treeItem);
            }
            return true;
          }
          return false;
        }}
        selectedTreeItems={selectedCustomers}
        setItemSelection={(item, selected): void => {
          if (selected) {
            updateSelectCustomers([item as CustomerItemType]);
          } else {
            updateSelectCustomers([]);
          }
        }}
        includeUnassigned={false}
        onlyCustomers={true}
        wideMode={false}
        expandedItems={expandedItems}
        expandItem={expandItem}
        expandItems={expandItems}
        collapseItem={collapseItem}
      />
    </ViewWithHeader>
  );
}
