import { mutate, SWRResponse } from 'swr';
import { ServiceLog, ServiceLogContent, ServiceLogCount } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest } from '../utils/dataSenders';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useServiceLogTypes(accessToken: string | null): SWRResponse<string[], BackendError> {
  const fullUrl = getFullUrl('/service/event/type');

  return getUseSwr<string[]>(fullUrl, accessToken);
}

export function useServiceLogs(
  mui: string,
  skip: number,
  limit: number,
  accessToken: string | null
): SWRResponse<ServiceLog[], BackendError> {
  const fullUrl = getFullUrl(`/devices/${mui}/service/event`, {
    skip: skip,
    limit: limit,
  });

  return getUseSwr<ServiceLog[]>(fullUrl, accessToken);
}

export function useServiceLogCount(mui: string, accessToken: string | null): SWRResponse<ServiceLogCount, BackendError> {
  const fullUrl = getFullUrl(`/devices/${mui}/service/event/count`);

  return getUseSwr<ServiceLogCount>(fullUrl, accessToken);
}

export function mutateServiceLogs(mui: string, skip: number, limit: number): Promise<void> {
  const fullUrl = getFullUrl(`/devices/${mui}/service/event`, {
    skip: skip,
    limit: limit,
  });

  return mutate<void>(fullUrl);
}

export async function deleteServiceLog(
  mui: string,
  logId: string,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/devices/${mui}/service/event/${logId}`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function editServiceLog(
  mui: string,
  logId: string,
  data: ServiceLogContent,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/devices/${mui}/service/event/${logId}`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
