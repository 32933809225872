import React, { useContext, useEffect, useState } from 'react';
import InputLongText from '../../../common/InputLongText';
import Box from '@mui/material/Box';
import InputItem from '../../../common/InputItem';
import AddEditDialog from '../common/AddEditDialog';
import { CustomerItemType } from '../../../../model/frontendDataModels';
import { EditCustomerData } from '../../../../services/customerManipulation';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { timeZonesNames } from '@vvo/tzdb';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { AppContext } from '../../../../App';
import { BackendCustomer } from '../../../../model/backendDataModels';
import { LoginContext } from '../../../../Login';

type EditCustomerProps = {
  customer: CustomerItemType;
  open: boolean;
  close: () => void;
  updateCustomer: (treeCustomer: BackendCustomer, newData: EditCustomerData) => Promise<boolean>;
};

export default function EditCustomer({ customer, open, close, updateCustomer }: EditCustomerProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: treeCustomer, error: treeCustomerError } = useCustomer(customer.customerId, loginContext.accessToken);
  const { data: parent, error: parentError } = useCustomer(treeCustomer?.parentId, loginContext.accessToken);

  if (treeCustomerError) {
    appContext.addBackendError(treeCustomerError);
  }

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [timezone, setTimezone] = useState<string>(treeCustomer?.timezone ?? '');

  useEffect(() => {
    if (!treeCustomer) {
      return;
    }

    setName(treeCustomer.name);
    setDescription(treeCustomer.description);
    setTimezone(treeCustomer.timezone);
  }, [treeCustomer]);

  if (!treeCustomer) {
    return <></>;
  }

  return (
    <AddEditDialog
      title='customer'
      parentName={parent ? parent.name : '-'}
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok && timezone) {
          const result = await updateCustomer(treeCustomer, { name, description, timezone });
          if (!result) {
            setName(treeCustomer.name);
            setDescription(treeCustomer.description);
          }
        } else {
          setName(treeCustomer.name);
          setDescription(treeCustomer.description);
        }
        close();
      }}
      mode={'Edit'}
      size='sm'
      okDisabled={!timezone || name === ''}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
        <Box sx={{ paddingTop: 3 }}>
          <InputAutocomplete
            label='Time Zone'
            value={timezone || ''}
            items={timeZonesNames}
            updateValue={(v: string | null): void => {
              if (v) {
                setTimezone(v);
              }
            }}
          />
        </Box>
      </Box>
    </AddEditDialog>
  );
}
