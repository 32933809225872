import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Box, CircularProgress, InputAdornment, Typography, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { useSearchItem } from '../../../dataHooks/searchHooks';
import { AppContext } from '../../../App';
import { BackendFleetParent, SearchDevice, SearchItem } from '../../../model/backendDataModels';
import { Search } from '@mui/icons-material';
import { TreeItemType } from '../../../model/frontendDataModels';
import { LoginContext } from '../../../Login';
import { searchCustomerToItem, searchDeviceToItem, searchFleetgroupToItem, searchFleetToItem } from '../../../model/convertModels';

export type Searchable = {
  unassigned: boolean;
  item: SearchItem;
  searchString: string;
  displayString: string;
};

function getDeviceString(device: SearchDevice): string {
  if (device.deviceTag !== '') {
    return `${device.deviceId}, ${device.serialNumber}, ${device.type}, ${device.deviceTag}`;
  }

  return `${device.deviceId}, ${device.serialNumber}, ${device.type}`;
}

function getSuffix(deviceType: string): string {
  if (deviceType === 'gateway') {
    return 'gateway';
  }

  if (deviceType === 'charger') {
    return 'chargers';
  }

  return 'batteries';
}

function getCustomerId(parents: BackendFleetParent[]): string {
  return parents.at(-1)?.parentId ?? '';
}

type FleetDeviceSearchProps = {
  includeUnassigned: boolean;
  expandItems: (itemIds: string[]) => void;
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
};

function FleetDeviceSearch(props: FleetDeviceSearchProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();
  const [value, setValue] = useState<Searchable | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasSearched === false && searchValue !== '') {
        setSearchString(searchValue);
        setHasSearched(true);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue]);

  const { data, error, isLoading } = useSearchItem(searchString, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  const options: Searchable[] = [];
  if (data && hasSearched) {
    for (const customer of data.customers) {
      options.push({
        unassigned: false,
        item: customer,
        searchString: customer.name,
        displayString: customer.name,
      });
    }

    for (const fleetgroup of data.fleetgroups) {
      options.push({
        unassigned: false,
        item: fleetgroup,
        searchString: fleetgroup.name,
        displayString: fleetgroup.name,
      });
    }

    for (const fleet of data.fleets) {
      options.push({
        unassigned: false,
        item: fleet,
        searchString: fleet.name,
        displayString: fleet.name,
      });
    }

    for (const device of data.devices) {
      options.push({
        unassigned: false,
        item: device,
        searchString: getDeviceString(device),
        displayString: getDeviceString(device),
      });
    }

    if (props.includeUnassigned) {
      for (const device of data.unassignedDevices) {
        options.push({
          unassigned: true,
          item: device,
          searchString: getDeviceString(device),
          displayString: getDeviceString(device),
        });
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: `17px ${COMPONENT_PADDING}px`, marginBottom: '14px' }}>
      <Autocomplete
        sx={{ bgcolor: 'background.default', borderRadius: 2, width: '100%' }}
        clearOnEscape
        handleHomeEndKeys={false}
        inputValue={searchValue}
        value={value}
        forcePopupIcon={false}
        loading={isLoading}
        onInputChange={(event, value): void => {
          setSearchValue(value);
          setHasSearched(false);
        }}
        noOptionsText={<Typography variant='columnHeader'>{hasSearched ? 'No item found' : 'Type to search'}</Typography>}
        onChange={(event: React.SyntheticEvent, value: Searchable | null): void => {
          if (value) {
            setValue(null);

            if ('mui' in value.item) {
              const parents = value.item.parents.map(parent => parent?.itemId ?? '');
              if (value.unassigned) {
                props.setItemSelection(searchDeviceToItem(value.item), true);
                parents.push(`${getSuffix(value.item.deviceType)}_unassigned`);
                parents.push('unassigned');
              } else {
                props.setItemSelection(searchDeviceToItem(value.item), true);
                parents.push(`${getSuffix(value.item.deviceType)}_${value.item.fleetId}`);
              }

              props.expandItems(parents);
            } else if ('customerId' in value.item) {
              props.setItemSelection(searchCustomerToItem(value.item), true);
              props.expandItems([value.item.customerId, ...value.item.parents.map(parent => parent?.itemId ?? '')]);
            } else if ('fleetgroupId' in value.item) {
              props.setItemSelection(searchFleetgroupToItem(value.item), true);
              props.expandItems([value.item.fleetgroupId, ...value.item.parents.map(parent => parent?.itemId ?? '')]);
            } else if ('fleetId' in value.item) {
              props.setItemSelection(searchFleetToItem(value.item, getCustomerId(value.item.parents)), true);
              props.expandItems([value.item.fleetId, ...value.item.parents.map(parent => parent?.itemId ?? '')]);
            }
          }
        }}
        onKeyDown={(ev): void => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            setHasSearched(true);
            setSearchString(searchValue);
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => {
          return (
            <TextField
              {...params}
              label='Search'
              variant='filled'
              color='info'
              InputLabelProps={{ sx: { color: theme.typography.columnHeader.color, marginLeft: '8px' } }}
              sx={{ input: { color: theme.typography.columnHeader.color, marginLeft: '8px' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position='end' sx={{ marginBottom: '1em' }}>
                    {isLoading ? <CircularProgress size={24} /> : <Search />}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        options={options}
        getOptionLabel={(option: Searchable): string => option.searchString}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Searchable): React.ReactNode => {
          return (
            <li
              {...props}
              key={
                'mui' in option.item
                  ? option.item.mui
                  : 'customerId' in option.item
                  ? option.item.customerId
                  : 'fleetId' in option.item
                  ? option.item.fleetId
                  : option.item.fleetgroupId
              }
            >
              <Typography variant='columnHeader' sx={{ cursor: 'pointer' }}>
                {option.displayString}
              </Typography>
            </li>
          );
        }}
      />
    </Box>
  );
}

export default FleetDeviceSearch;
