import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import mapboxgl from 'mapbox-gl';
import Map, { Marker, NavigationControl, useMap } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

function fleetMapSvgIcon(color: string): string {
  const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="36" height="60">

  <g transform="matrix(3.7974687 0 0 3.7974687 -0 1.9936695)">
    <g>
      <g>
        <g>
            <path d="M9.48 4.81L9.48 4.67C 9.4413395 2.0521703 7.2878294 -0.03866005 4.6699996 0C 2.0521703 0.03866005 -0.03866005
            2.1921704 0 4.81C 0 8.4 4.74 14.75 4.74 14.75C 4.74 14.75 9.48 8.65 9.48 4.8100004L9.48 4.8100004L9.48 4.81zM4.74 6.56C
            3.7127504 6.5600004 2.88 5.7272496 2.88 4.7C 2.8800006 3.6727502 3.712751 2.8400002 4.7400007 2.8400002C 5.76725 2.8400006
            6.6000004 3.6727505 6.6 4.7C 6.6000004 5.193303 6.4040365 5.6664014 6.0552187 6.0152187C 5.706401 6.3640366 5.233303 6.5600004
            4.74 6.56" stroke="none" fill="${color}" fill-rule="nonzero" />
        </g>
      </g>
    </g>
  </g>
  </svg>`;
  return `data:image/svg+xml;base64,${btoa(svgStr)}`;
}

export type LngLatType = {
  lng: number;
  lat: number;
};

type HandleGeocoder = {
  setLatLng: (latLng?: LngLatType) => void;
};

function Geocoder({ setLatLng }: HandleGeocoder): JSX.Element {
  const map = useMap();

  const geocoder = new MapboxGeocoder({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
    mapboxgl: mapboxgl,
    marker: undefined,
  });

  geocoder.on('result', (e): void => setLatLng({ lng: e.result.center[0], lat: e.result.center[1] }));

  useEffect(() => {
    if (map.current) {
      map.current.addControl(geocoder);
    }

    return () => {
      if (map.current) {
        if (map.current.hasControl(geocoder)) {
          map.current.removeControl(geocoder);
        }
      }
    };
  }, [map]);

  return <></>;
}

type AddEditFleetMapProps = {
  fleetLocation?: LngLatType;
  updateLocation: (location?: LngLatType) => void;
};

export default function AddEditFleetMap({ fleetLocation, updateLocation }: AddEditFleetMapProps): JSX.Element {
  const [latLng, setLatLng] = useState<LngLatType | undefined>(fleetLocation);

  useEffect(() => {
    updateLocation(latLng);
  }, [latLng]);

  useEffect(() => {
    setLatLng(fleetLocation);
  }, [fleetLocation]);

  const mapBoxUsername = process.env.REACT_APP_MAPBOX_USERNAME || '';
  const mapBoxStyleId = process.env.REACT_APP_MAPBOX_STYLE_ID || '';
  const mapBoxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

  const mapBoxUrl = `https://api.mapbox.com/styles/v1/${mapBoxUsername}/${mapBoxStyleId}?access_token=${mapBoxAccessToken}`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
      <Map
        initialViewState={{
          latitude: fleetLocation ? fleetLocation.lat : 52.871956,
          longitude: fleetLocation ? fleetLocation.lng : -1.419883,
          zoom: fleetLocation ? 5 : 0,
        }}
        style={{ height: '100%', width: '100%' }}
        mapStyle={mapBoxUrl}
        onClick={(e): void => setLatLng(e.lngLat)}
        cursor={'crosshair'}
      >
        <Geocoder setLatLng={setLatLng} />
        {latLng && (
          <Marker
            longitude={latLng.lng}
            latitude={latLng.lat}
            anchor='bottom'
            onClick={(e): void => {
              e.originalEvent.stopPropagation();
              setLatLng(undefined);
            }}
          >
            <img src={fleetMapSvgIcon('#D5D5D5')} />
          </Marker>
        )}
        <NavigationControl />
      </Map>
    </Box>
  );
}
