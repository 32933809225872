import React, { useContext } from 'react';
import ComponentBase from '../../componentBase/ComponentBase';
import LineGraph from '../../LineGraph';
import { useEqualizingCompletion } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import { AppContext } from '../../../../../App';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import MonthAndYearPicker from '../../../../common/monthAndYearPicker/MonthAndYearPicker';
import moment from 'moment';
import { BackendFleet, BackendFleetgroup, isBackendFleet } from '../../../../../model/backendDataModels';
import { LoginContext } from '../../../../../Login';

type Props = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  title: string;
  startDate: Date;
  endDate: Date;
  onMonthSelected: (month: number) => void;
  onYearSelected: (year: number) => void;
  help?: React.ReactNode;
};

export default function WeeklyEqCompGraph({
  selectedFleetOrFleetgroup,
  title,
  startDate,
  endDate,
  onMonthSelected,
  onYearSelected,
  help,
}: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const startYear = moment(startDate).startOf('year');
  const endYear = moment(endDate).endOf('year');

  const { data, error } = useEqualizingCompletion(
    isBackendFleet(selectedFleetOrFleetgroup) ? 'fleet' : 'fleetgroup',
    isBackendFleet(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetId : selectedFleetOrFleetgroup.fleetgroupId,
    startYear.toDate(),
    endYear.toDate(),
    loginContext.accessToken
  );

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <ComponentBase title={title} help={help} doubleSize={true}>
      <MonthAndYearPicker
        year={startDate.getFullYear()}
        month={startDate.getMonth()}
        onYearSelected={onYearSelected}
        onMonthSelected={onMonthSelected}
      />
      {data && !error && <LineGraph data={data} />}
      {!data && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
