import React, { useContext, useState } from 'react';
import InputLongText from '../../../common/InputLongText';
import Box from '@mui/material/Box';
import InputItem from '../../../common/InputItem';
import AddEditDialog from '../common/AddEditDialog';
import { CustomerItemType } from '../../../../model/frontendDataModels';
import { AddCustomerData } from '../../../../services/customerManipulation';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { timeZonesNames } from '@vvo/tzdb';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';

type AddCustomerProps = {
  parent: CustomerItemType;
  open: boolean;
  close: () => void;
  addNewCustomer: (newCustomer: AddCustomerData) => Promise<void>;
};

export default function AddCustomer(props: AddCustomerProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [timezone, setTimezone] = useState<string>(timeZonesNames[0]);

  const { data: parent, error } = useCustomer(props.parent.customerId, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <AddEditDialog
      title='customer'
      open={props.open}
      close={async (ok: boolean): Promise<void> => {
        if (ok && timezone) {
          await props.addNewCustomer({ name, description, parentId: props.parent.customerId, timezone });
        }

        setName('');
        setDescription('');
        setTimezone(timeZonesNames[0]);
        props.close();
      }}
      mode={'Add'}
      size='sm'
      parentName={parent?.name ?? ''}
      okDisabled={!timezone || name === ''}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
        <Box sx={{ paddingTop: 3 }}>
          <InputAutocomplete
            label='Time Zone'
            value={timezone}
            items={timeZonesNames}
            updateValue={(v: string | null): void => {
              if (v) {
                setTimezone(v);
              }
            }}
          />
        </Box>
      </Box>
    </AddEditDialog>
  );
}
