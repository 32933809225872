import React from 'react';
import { Box } from '@mui/material';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import ServiceLogDataGrid from './serviceLogCommon/ServiceLogDataGrid';

type ServiceLogTabProps = {
  mui: string;
};

export default function ServiceLogTab(props: ServiceLogTabProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: '2px solid black',
        padding: `${COMPONENT_PADDING}px`,
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <ServiceLogDataGrid mui={props.mui} />
    </Box>
  );
}
