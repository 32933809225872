import { SWRResponse } from 'swr';
import { SearchResult } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useSearchItem(searchString: string | undefined, accessToken: string | null): SWRResponse<SearchResult, BackendError> {
  const fullUrl = searchString ? getFullUrl(`/search/item?searchString=${searchString}`) : null;

  return getUseSwr<SearchResult>(fullUrl, accessToken);
}
