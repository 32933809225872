import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import Icon from '../Icon';
import CustomerTree from './CustomerTree';
import { useCustomer } from '../../../dataHooks/customerHooks';
import { AppContext } from '../../../App';
import { TreeItemType } from '../../../model/frontendDataModels';
import { LoginContext } from '../../../Login';

type CustomerTreeProps = {
  customerId: string;
  includeUnassigned: boolean;
  onlyCustomers: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  wideMode: boolean;
  canSelect: (treeItem: TreeItemType) => boolean;
  expandedItems: string[];
  expandItem: (item: string) => void;
  collapseItem: (item: string) => void;
};

export default function TopCustomerTree(props: CustomerTreeProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: customer, error } = useCustomer(props.customerId, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
      }}
    >
      <Typography
        variant='columnHeader'
        sx={{
          margin: '0px 24px 4px 59px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Name
        <Icon name='online' sx={{ fontSize: '16px' }} />
      </Typography>
      {customer ? (
        <CustomerTree
          customer={customer}
          depth={0}
          includeUnassigned={props.includeUnassigned}
          onlyCustomers={props.onlyCustomers}
          wideMode={props.wideMode}
          selectedTreeItems={props.selectedTreeItems}
          setItemSelection={props.setItemSelection}
          canSelect={props.canSelect}
          expandedItems={props.expandedItems}
          expandItem={props.expandItem}
          collapseItem={props.collapseItem}
        />
      ) : null}
    </Box>
  );
}
