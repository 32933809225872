import { SWRResponse } from 'swr';
import { Alarms, LineChartData } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useBatteryAlarms(
  type: 'bms' | 'bmu',
  mui: string,
  startDate: Date,
  endDate: Date,
  accessToken: string | null
): SWRResponse<Alarms, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/overview/alarms`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
  return getUseSwr<Alarms>(fullUrl, accessToken);
}

export function useBatteryMinSOC(
  type: 'bms' | 'bmu',
  mui: string,
  startDate: Date,
  endDate: Date,
  accessToken: string | null
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/overview/minsoc`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
  return getUseSwr<LineChartData>(fullUrl, accessToken);
}
