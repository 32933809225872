import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../../../common/Icon';
import { BackendDevice } from '../../../../model/backendDataModels';

type Props = {
  status: BackendDevice;
};

export default function GatewayType({ status }: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '19px',
        marginLeft: '22px',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='sectionHeader'
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Icon name={status.productCategory} />
        <Box sx={{ marginLeft: '1em' }}>{status.type}</Box>
      </Typography>
    </Box>
  );
}
