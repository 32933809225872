import React from 'react';
import ExpandableRow from '../ExpandableRow';
import Box from '@mui/material/Box';
import { Table, TableHead, TableBody } from '@mui/material';
import { TreeItemType, isDeviceItem } from '../../../../model/frontendDataModels';
import DeviceTableRow from './DeviceTableRow';
import DeviceTableHeader from './DeviceTableHeader';
import { BackendDevice } from '../../../../model/backendDataModels';
import { backendDeviceToItem } from '../../../../model/convertModels';

type DeviceListProps = {
  name: string;
  suffix: 'batteries' | 'chargers';
  iconName: 'battery' | 'charger';
  fleetId: string;
  customerId: string;
  devices: BackendDevice[];
  depth: number;
  unassigned: boolean;
  wideMode: boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
  loading: boolean;
  expandedItems: string[];
  expandItem: (item: string) => void;
  collapseItem: (item: string) => void;
};

export default function DeviceList(props: DeviceListProps): JSX.Element {
  const unassigned = props.fleetId === 'unassigned';
  const itemId = unassigned ? `${props.suffix}_${props.fleetId}_${props.customerId}` : `${props.suffix}_${props.fleetId}`;
  const expanded = props.expandedItems.find(id => id === itemId) !== undefined;

  function expandTree(): void {
    props.expandItem(itemId);
  }

  function collapseTree(): void {
    props.collapseItem(itemId);
  }

  return (
    <ExpandableRow
      label={props.name}
      iconName={props.iconName}
      depth={props.depth}
      selected={false}
      expanded={expanded}
      expandTree={expandTree}
      collapseTree={collapseTree}
      hasWarning={props.devices.findIndex(device => device.warning ?? false) !== -1}
      loading={props.loading}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Table
          size='small'
          sx={{
            width: '100%',
            whiteSpace: 'nowrap' as const,
            position: 'relative' as const,
            tableLayout: 'fixed' as const,
          }}
        >
          <TableHead>
            <DeviceTableHeader unassigned={props.unassigned} wideMode={props.wideMode} />
          </TableHead>
          <TableBody>
            {props.devices.map(device => {
              return (
                <DeviceTableRow
                  key={device.mui}
                  device={device}
                  unassigned={props.unassigned}
                  wideMode={props.wideMode}
                  selectedTreeItems={props.selectedTreeItems}
                  setItemSelection={(item: TreeItemType, selected: boolean, event: React.MouseEvent): void => {
                    if (selected) {
                      if (event.shiftKey) {
                        // Only select multiple items if exactly one is already selected
                        if (props.selectedTreeItems.length === 1 && isDeviceItem(props.selectedTreeItems[0]) && isDeviceItem(item)) {
                          const selectedItem = props.selectedTreeItems[0];
                          const selectedIndex = props.devices.findIndex(device => device.mui === selectedItem.mui);

                          // Also, make sure the previous selected is part of the current device list
                          const thisIndex = props.devices.findIndex(device => device.mui === item.mui);
                          if (selectedIndex >= 0 && thisIndex >= 0) {
                            const startIndex = Math.min(selectedIndex, thisIndex);
                            const endIndex = Math.max(selectedIndex, thisIndex) + 1;
                            props.devices
                              .slice(startIndex, endIndex)
                              .forEach(device => props.setItemSelection(backendDeviceToItem(device), true));
                          } else {
                            props.setItemSelection(item, selected);
                          }
                        } else {
                          props.setItemSelection(item, selected);
                        }
                      } else {
                        props.setItemSelection(item, selected);
                      }
                    } else {
                      props.setItemSelection(item, selected);
                    }
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </ExpandableRow>
  );
}
