import React from 'react';
import { TableRow, TableCell, useTheme, SxProps as MuiSxProps } from '@mui/material';

type DeviceTableHeaderProps = {
  unassigned: boolean;
  wideMode: boolean;
};

export default function DeviceTableHeader(props: DeviceTableHeaderProps): JSX.Element | null {
  const theme = useTheme();

  const narrowWidths = props.unassigned ? ['30%', '30%', '40%'] : ['40%', '40%', '20%'];
  const wideWidths = props.unassigned ? ['20%', '22%', '18%', '21%', '24%', '24%'] : ['20%', '20%', '20%', '22%', '15%', '15%'];

  const sxBase = theme.typography.columnHeader;

  const sx: MuiSxProps = {
    ...sxBase,
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'clip',
    userSelect: 'none',
  };
  const warningSx: MuiSxProps = {
    ...sx,
    color: theme.palette.error.main,
  };

  return (
    <TableRow>
      <TableCell width={props.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
        {'Device ID'}
      </TableCell>
      {props.wideMode && (
        <TableCell width={props.wideMode ? wideWidths[1] : narrowWidths[0]} sx={sx}>
          {'Serial No'}
        </TableCell>
      )}
      <TableCell width={props.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
        {'Type'}
      </TableCell>
      {props.wideMode && (
        <>
          <TableCell width={wideWidths[3]} sx={sx}>
            {'Last seen'}
          </TableCell>
          {!props.unassigned && (
            <TableCell width={wideWidths[4]} sx={sx}>
              {'State'}
            </TableCell>
          )}
        </>
      )}
      <TableCell width={props.wideMode ? wideWidths[5] : narrowWidths[2]} sx={props.unassigned ? sx : warningSx}>
        {props.unassigned ? 'Last seen by' : ''}
      </TableCell>
    </TableRow>
  );
}
