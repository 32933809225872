export interface UnassignedFleetItemType {
  type: 'FleetUnassigned';
  fleetId: string;
  customerId: string;
  parentName: string;
}

export interface FleetItemType {
  type: 'Fleet';
  fleetId: string;
  customerId: string;
  parentType: string;
  parentId: string;
  name: string;
}

export interface FleetgroupItemType {
  type: 'Fleetgroup';
  fleetgroupId: string;
  parentType: string;
  parentId: string;
  name: string;
}

export interface CustomerItemType {
  type: 'Customer';
  name: string;
  customerId: string;
  parentId: string;
}

export interface DeviceItemType {
  type: 'Device';
  mui: string;
  customerId: string;
  fleetId: string;
}

export type TreeItemType = UnassignedFleetItemType | FleetItemType | FleetgroupItemType | CustomerItemType | DeviceItemType;

export function isCustomerItem(object?: TreeItemType): object is CustomerItemType {
  if (object === undefined) {
    return false;
  }

  return 'customerId' in object && 'name' in object && 'type' in object && object['type'] === 'Customer';
}

export function isDeviceItem(object?: TreeItemType): object is DeviceItemType {
  if (object === undefined) {
    return false;
  }

  return 'mui' in object && 'customerId' in object && 'fleetId' in object && 'type' in object && object['type'] === 'Device';
}

export function isUnassignedFleetItem(object?: TreeItemType): object is UnassignedFleetItemType {
  if (object === undefined) {
    return false;
  }

  return (
    'fleetId' in object && 'customerId' in object && 'parentName' in object && 'type' in object && object['type'] === 'FleetUnassigned'
  );
}

export function isFleetItem(object?: TreeItemType): object is FleetItemType {
  if (object === undefined) {
    return false;
  }

  return 'fleetId' in object && 'type' in object && object['type'] === 'Fleet';
}

export function isFleetgroupItem(object?: TreeItemType): object is FleetgroupItemType {
  if (object === undefined) {
    return false;
  }

  return 'fleetgroupId' in object && 'type' in object && object['type'] === 'Fleetgroup';
}
