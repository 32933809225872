import React from 'react';
import Box from '@mui/material/Box';
import { TreeItemType } from '../../../model/frontendDataModels';
import AddButtons from './addButtons/AddButtons';
import EditButton from './editButton/EditButton';
import DeleteButton from './deleteButton/DeleteButton';

type AddEditDeleteFooterProps = {
  selectedTreeItems: TreeItemType[];
  clearSelection: () => void;
};

export default function AddEditDeleteFooter(props: AddEditDeleteFooterProps): JSX.Element {
  const selectedTreeItem = props.selectedTreeItems.length === 1 ? props.selectedTreeItems[0] : undefined;

  return (
    <Box sx={{ width: '100%', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <AddButtons selectedItem={selectedTreeItem} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
          <EditButton selectedItem={selectedTreeItem} />
          <DeleteButton selectedTreeItems={props.selectedTreeItems} clearSelection={props.clearSelection} />
        </Box>
      </Box>
    </Box>
  );
}
