import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../App';
import LogFilesTab from './LogFilesTab';
import { useDevice } from '../../../../dataHooks/deviceHooks';
import { LoginContext } from '../../../../Login';

type LogFilesProps = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function LogFiles({ changeHelpBox }: LogFilesProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [selectedTabName, setSelectedTabName] = React.useState<string>('');

  const params = useParams();

  const { data: device, error } = useDevice(params.mui, loginContext.accessToken);

  useEffect(() => {
    changeHelpBox(undefined);
  }, []);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <>
      {device && (
        <LogFilesTab treeDevice={device} tabMode={false} selectedTabName={selectedTabName} setSelectedTabName={setSelectedTabName} />
      )}
    </>
  );
}
