import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { TreeItemType } from '../../../model/frontendDataModels';
import { ViewWithHeader } from '../common/ViewWithHeader';
import SearchableTree from '../../common/searchableTree/SearchableTree';

type Props = {
  customerId: string;
  canSelect: (treeItem: TreeItemType) => boolean;
  selectedTreeItems: TreeItemType[];
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
};

export default function UserAccessSelector({ customerId, canSelect, selectedTreeItems, setItemSelection }: Props): JSX.Element {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  function expandItem(itemId: string): void {
    setExpandedItems([...expandedItems, itemId]);
  }

  function expandItems(items: string[]): void {
    setExpandedItems([...expandedItems, ...items]);
  }

  function collapseItem(itemId: string): void {
    setExpandedItems(expandedItems.filter(id => id !== itemId));
  }

  return (
    <ViewWithHeader
      sx={{
        minWidth: 400,
        borderRight: '2px solid black',
      }}
      header={
        <>
          <Box sx={{ marginLeft: '1em' }}>{'User Access'}</Box>
        </>
      }
    >
      <SearchableTree
        customerId={customerId}
        canSelect={canSelect}
        selectedTreeItems={selectedTreeItems}
        setItemSelection={setItemSelection}
        includeUnassigned={false}
        onlyCustomers={true}
        wideMode={false}
        expandedItems={expandedItems}
        expandItem={expandItem}
        expandItems={expandItems}
        collapseItem={collapseItem}
      />
    </ViewWithHeader>
  );
}
