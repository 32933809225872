import React from 'react';
import { Box, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Typography from '@mui/material/Typography';
import { SxProps } from './commonTypes';
import { COMPONENT_PADDING } from '../../themes/theme';

type TabLabelProps = {
  icon?: JSX.Element | string;
  label?: string;
  selected: boolean;
};

function TabLabel({ icon, label, selected }: TabLabelProps): JSX.Element {
  const theme = useTheme();

  return (
    <Typography
      variant='highlightText'
      component='div'
      sx={{
        color: selected ? theme.palette.info.main : '#7297AC',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon && <Box sx={{ margin: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{icon}</Box>}
      {label && <Box sx={{ margin: '5px', fontWeight: selected ? 'bold' : 'normal' }}>{label}</Box>}
    </Typography>
  );
}

export type Page = {
  name: string; // Has to be unique
  icon?: JSX.Element | string;
  body: JSX.Element;
};

type TabbedPageProps = {
  pages: Page[];
  selectedPage: string;
  setSelectedPage: (selectedPage: string) => void;
  short?: boolean;
  initialized?: boolean;
  tabAlignment?: 'left' | 'right';
  disableScroll?: boolean;
};

export default function TabbedPage({
  pages,
  selectedPage,
  setSelectedPage,
  short = false,
  initialized = true,
  sx,
  tabAlignment = 'left',
  disableScroll = false,
}: TabbedPageProps & SxProps): JSX.Element {
  const validSelectedPage = pages.find(page => page.name === selectedPage)?.name ?? pages[0].name;

  return (
    <Box sx={{ flex: 1, display: initialized ? 'flex' : 'none', flexDirection: 'column', minHeight: 0, ...sx }}>
      <TabContext value={validSelectedPage}>
        <Box sx={{ display: 'flex', justifyContent: tabAlignment === 'left' ? 'start' : 'end', padding: `0 ${COMPONENT_PADDING}px` }}>
          <TabList
            variant='scrollable'
            scrollButtons='auto'
            selectionFollowsFocus
            textColor='inherit'
            onChange={(_event: React.SyntheticEvent, value: string): void => setSelectedPage(value)}
          >
            {pages.map((page, index) => (
              <Tab
                key={index}
                iconPosition='start'
                label={<TabLabel selected={page.name === selectedPage} icon={page.icon} label={short && page.icon ? '' : page.name} />}
                value={page.name}
              />
            ))}
          </TabList>
        </Box>

        {pages.map((page, index) => (
          <TabPanel
            key={index}
            value={page.name}
            sx={{ padding: '0px', flex: 1, minHeight: 0, overflow: disableScroll ? 'default' : 'auto' }}
          >
            {page.body}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
