import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest } from '../utils/dataSenders';

export async function deleteDevice(mui: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = `/device/${mui}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export type MoveDevicesData = {
  fleetId: string;
  customerId: string;
  muis: string[];
};

export async function moveDevices(
  data: MoveDevicesData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = '/device/move';
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
