import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import InputItem from '../../../common/InputItem';
import { CustomerItemType, FleetgroupItemType, isCustomerItem, isFleetgroupItem } from '../../../../model/frontendDataModels';
import InputLongText from '../../../common/InputLongText';
import getTimeZone from '../../../../services/getTimeZone';
import { AddFleetData } from '../../../../services/fleetManipulation';
import reverseGeocoding from '../../../../services/reverseGeocoding';
import { AppContext } from '../../../../App';
import { useCustomer } from '../../../../dataHooks/customerHooks';
import { useFleetgroup } from '../../../../dataHooks/fleetAndFleetgroupHooks';
import AddEditFleetMap, { LngLatType } from '../common/addEditFleet/AddEditFleetMap';
import AddEditDialog from '../common/AddEditDialog';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { LoginContext } from '../../../../Login';

function prettifyLocation(location: LngLatType): string {
  const obj: { lat: number; lng: number } = JSON.parse(JSON.stringify(location));
  const value = `Lat: ${obj.lat.toFixed(6)}, Lng: ${obj.lng.toFixed(6)}`;
  return value;
}

type AddFleetProps = {
  parent: CustomerItemType | FleetgroupItemType;
  open: boolean;
  close: () => void;
  addFleet: (fleetData: AddFleetData) => Promise<boolean>;
};

export default function AddFleet(props: AddFleetProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [location, setLocation] = useState<LngLatType | undefined>(undefined);
  const [address, setAddress] = useState<string | null>(null);
  const [timeZoneFromLocation, setTimeZoneFromLocation] = useState<string | undefined>(undefined);
  const [timeZoneFromList, setTimeZoneFromList] = useState<string | null>(null);

  const { data: parent, error: parentError } = isCustomerItem(props.parent)
    ? useCustomer(props.parent.customerId, loginContext.accessToken)
    : useFleetgroup(props.parent.fleetgroupId, loginContext.accessToken);

  if (parentError) {
    appContext.addBackendError(parentError);
  }

  useEffect(() => {
    if (location) {
      updateTimeZone(location);
      setLat(location.lat);
      setLng(location.lng);
      updateAddress(location);
    } else {
      setTimeZoneFromLocation(undefined);
      setLat(null);
      setLng(null);
      setAddress(null);
    }
  }, [location]);

  async function updateTimeZone(location: LngLatType): Promise<void> {
    const timezone = await getTimeZone(location);
    if (timezone) {
      setTimeZoneFromLocation(timezone);
      setTimeZoneFromList(timezone);
    }
  }

  function resetValues(): void {
    setName('');
    setDescription('');
    setLocation(undefined);
    setTimeZoneFromList(null);
  }

  async function updateAddress(location: LngLatType): Promise<void> {
    const ad = await reverseGeocoding(location);
    setAddress(ad);
  }

  return (
    <AddEditDialog
      title='fleet'
      parentName={parent?.name ?? ''}
      open={props.open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          const addData: AddFleetData = {
            name,
            description,
            isParentFleetgroup: isFleetgroupItem(props.parent),
            parentId: isFleetgroupItem(props.parent) ? props.parent.fleetgroupId : props.parent.customerId,
            lat,
            lng,
            address,
            timezone: timeZoneFromLocation ? timeZoneFromLocation : timeZoneFromList ? timeZoneFromList : '',
          };

          await props.addFleet(addData);
        }

        resetValues();
        props.close();
      }}
      mode={'Add'}
      okDisabled={name === '' || !((location && timeZoneFromLocation) || timeZoneFromList)}
      size='lg'
    >
      <Stack direction='row' spacing={2} sx={{ height: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '35%', height: '100%' }}>
          <InputItem label='Name' value={name} updateValue={setName} />
          <InputLongText label='Description' value={description} updateValue={setDescription} />
          {address && <Typography variant='tableText'>{`Address: ${address}`}</Typography>}
          {location && <Typography variant='tableText'>{prettifyLocation(location)}</Typography>}
          {timeZoneFromLocation && <Typography variant='tableText'>{timeZoneFromLocation}</Typography>}
          <InputAutocomplete
            label='Time Zone'
            value={timeZoneFromList || ''}
            items={timeZonesNames}
            updateValue={setTimeZoneFromList}
            disabled={location !== undefined ? true : undefined}
          />
        </Box>
        <AddEditFleetMap
          fleetLocation={location}
          updateLocation={(location?: LngLatType): void => {
            setLocation(location);
          }}
        />
      </Stack>
    </AddEditDialog>
  );
}
