import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import TabbedPage from '../../../common/TabbedPage';
import { useBmsBasicLogInfo, useBmsHistogram } from '../../../../dataHooks/bmsHooks';
import LoadingIndicator from '../../../common/LoadingIndicator';
import HistogramBarGraph, { CategoriesAndValues } from './HistogramBarGraph';
import { Divider } from '@mui/material';
import { AppContext } from '../../../../App';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';
import { BackendDevice } from '../../../../model/backendDataModels';
import { LoginContext } from '../../../../Login';

function getCategories(xValues: number[]): string[] {
  const categories = xValues.map((c: number, index: number): string => {
    if (index === 0) {
      return `< ${c.toString()}`;
    }
    return `${xValues[index - 1]} - ${c.toString()}`;
  });
  categories.push(`> ${xValues[xValues.length - 1].toString()}`);
  return categories;
}

type BmsHistogramsProps = {
  title: string;
  minData: CategoriesAndValues;
  maxData: CategoriesAndValues;
};

function BmsHistograms({ title, minData, maxData }: BmsHistogramsProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }} width='100%' height='310px'>
      <Typography variant='graphHeader'>Max {title}</Typography>
      <HistogramBarGraph data={maxData} />
      <Divider />
      <Typography variant='graphHeader'>Min {title}</Typography>
      <HistogramBarGraph data={minData} />
    </Box>
  );
}

type Props = {
  selectedDevice: BackendDevice;
};

export default function BmsHistogramTab({ selectedDevice }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const [selectedPage, setSelectedPage] = React.useState<string>('Temperature max/min');

  const { data: histograms, error } = useBmsHistogram(selectedDevice.mui, loginContext.accessToken);
  const { data: basicLogsInfo, error: errorBasicLogInfo } = useBmsBasicLogInfo(selectedDevice.mui, loginContext.accessToken);

  if (error || errorBasicLogInfo) {
    if (error) {
      appContext.addBackendError(error);
    }
    if (errorBasicLogInfo) {
      appContext.addBackendError(errorBasicLogInfo);
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px` }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Histogram
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!histograms || !basicLogsInfo) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px` }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Histogram
          </Typography>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  }

  const temperatureCategories = getCategories(histograms.temperature.xvalues);

  const temperatureMinData: CategoriesAndValues = {
    categories: temperatureCategories,
    values: histograms.temperature.y1values,
  };
  const temperatureMaxData: CategoriesAndValues = {
    categories: temperatureCategories,
    values: histograms.temperature.y2values,
  };

  const voltageCategories = getCategories(histograms.voltage.xvalues);

  const voltageMinData: CategoriesAndValues = {
    categories: voltageCategories,
    values: histograms.voltage.y1values,
  };

  const voltageMaxData: CategoriesAndValues = {
    categories: voltageCategories,
    values: histograms.voltage.y2values,
  };

  const currentCategories = getCategories(histograms.current.xvalues);

  const currentMinData: CategoriesAndValues = {
    categories: currentCategories,
    values: histograms.current.y1values,
  };
  const currentMaxData: CategoriesAndValues = {
    categories: currentCategories,
    values: histograms.current.y1values,
  };
  const timestampDate = moment(basicLogsInfo.latestHistogramUpdate, moment.ISO_8601).tz(state.timezone);
  const latestHistogramUpdate = timestampDate.format('YYYY-MM-DD HH:mm:ss');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <DeviceHeaderInfo label='Serial number' text={basicLogsInfo.serialNumber} />
          <DeviceHeaderInfo label='Latest histogram update' text={latestHistogramUpdate} />
        </Box>
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Histogram
        </Typography>
        <TabbedPage
          pages={[
            {
              name: 'Temperature max/min',
              body: <BmsHistograms title='temperature' minData={temperatureMinData} maxData={temperatureMaxData} />,
            },
            {
              name: 'Voltage max/min',
              body: <BmsHistograms title='voltage' minData={voltageMinData} maxData={voltageMaxData} />,
            },
            {
              name: 'Current max/min',
              body: <BmsHistograms title='current' minData={currentMinData} maxData={currentMaxData} />,
            },
          ]}
          disableScroll={true}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </Box>
    </Box>
  );
}
